<template>
  <div>
    <b>{{ membership.membership }}</b>&nbsp;
    <span v-if="membership.membership === 'premium'">bis</span>
    <span v-else>seit</span>
    {{ endDate.day }}.{{ endDate.month }}.{{ endDate.year }}, {{ endDate.hour }}:{{ endDate.minutes }} Uhr
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    membership: Object,
    user: Object,
    endDate: Object
  },
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
</style>
