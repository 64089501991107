export default {
  install (Vue, options) {
    Vue.prototype.$getNullsBefore = function (nbr) {
      if (nbr * 1 >= 10) {
        return nbr
      }
      return 0 + '' + nbr
    }
    Vue.prototype.$nl2br = function (str, isXhtml) {
      if (typeof str === 'undefined' || str === null) {
        return ''
      }
      var breakTag = (isXhtml || typeof isXhtml === 'undefined') ? '<br />' : '<br>'
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
    }
  }
}
