<template>
  <span v-if="newDate">
    <flixIcon id="calendar" />
    <span v-if="today"> {{ $t('message.today') }} </span>
    <span v-else-if="yesterday"> {{ $t('message.yesterday') }} </span>
    <span v-else> {{ $getNullsBefore(newDate.getDate()) }}.{{ $getNullsBefore(newDate.getMonth() + 1) }}.{{ $getNullsBefore(newDate.getFullYear()) }}</span>
    <span v-if="newDate.getHours() || newDate.getMinutes()">
      <flixIcon id="clock" /> {{ $getNullsBefore(newDate.getHours()) }}:{{ $getNullsBefore(newDate.getMinutes()) }}
    </span>
  </span>
</template>
<script>
export default {
  components: {},
  props: {
    date: String
  },
  mounted () {
    this.getNewDate()
  },
  computed: {

  },
  data () {
    return {
      newDate: false,
      today: false,
      yesterday: false
    }
  },
  methods: {
    getNewDate () {
      this.newDate = this.date.split(' ')
      this.newDate[0] = this.newDate[0].split('-')
      this.newDate[1] = this.newDate[1].split(':')

      var nd = new Date()
      nd.setYear(this.newDate[0][0] * 1)
      nd.setMonth(this.newDate[0][1] * 1 - 1)
      nd.setDate(this.newDate[0][2] * 1)
      nd.setHours(this.newDate[1][0] * 1)
      nd.setMinutes(this.newDate[1][1] * 1)

      var now = new Date()
      if (nd.getFullYear() === now.getFullYear()) {
        if (nd.getMonth() === now.getMonth()) {
          if (nd.getDate() === now.getDate()) {
            this.today = true
          }
        }
      }
      now.setDate(now.getDate() - 1)
      if (nd.getFullYear() === now.getFullYear()) {
        if (nd.getMonth() === now.getMonth()) {
          if (nd.getDate() === now.getDate()) {
            this.yesterday = true
          }
        }
      }

      this.newDate = nd
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
