<template>
  <a href="#" class="flix-btn flix-btn-edit flix-btn-warning" :class="{'flix-btn-block': settings && settings.block}" @click.prevent="onClick"><slot name="icon"><flixIcon class="icon" :id="'download'" /></slot> <slot name="text">{{ $t('message.edit', {name: ''}) }}</slot></a>
</template>
<script>
export default {
  components: {},
  props: {
    onClick: Function,
    settings: Object
  },
  data () {
    return {
    }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  @import '@/assets/style/sass/flixDefaultBtn.sass'
</style>
