export default {
  install (Vue, options) {
    Vue.prototype.$createDate = function (string) {
      string = string.split(' ')
      string[0] = string[0].split('-')
      string[1] = string[1].split(':')
      return new Date(string[0][0], (string[0][1] - 1), string[0][2], string[1][0], string[1][1], 0)
    }
    Vue.prototype.$getWeekday = function (date) {
      var weekday = date.getDay()

      if (weekday === 0) {
        weekday = 7
      }

      return weekday - 1
    }
    Vue.prototype.$getStatusClass = function (status) {
      switch (status) {
        case 'commitment':
          return 'success'
        case 'cancellation':
          return 'danger'
        case 'open':
          return 'warning'
      }
    }
    Vue.prototype.$getStatusIcon = function (entry) {
      switch (entry.status) {
        case 'commitment':
          return 'thumbs-up'
        case 'cancellation':
          return 'thumbs-down'
        case 'open':
          return 'warning-sign'
      }
    }
    Vue.prototype.$beautifyDate = function (date) {
      var begin
      var end
      if (typeof date.begin === 'string') {
        begin = Vue.prototype.$createDate(date.begin)
      } else if (typeof date.begin.getMonth === 'function') {
        begin = date.begin
      }
      if (typeof date.end === 'string') {
        end = Vue.prototype.$createDate(date.end)
      } else if (typeof date.begin.getMonth === 'function') {
        end = date.end
      }

      var r = Vue.prototype.$getNullsBefore(begin.getDate()) + '.' + Vue.prototype.$getNullsBefore(begin.getMonth() + 1) + '.' + begin.getFullYear() + ', ' + Vue.prototype.$getNullsBefore(begin.getHours()) + ':' + Vue.prototype.$getNullsBefore(begin.getMinutes())
      if (begin.getFullYear() !== end.getFullYear() || begin.getMonth() + 1 !== end.getMonth() + 1 || begin.getDate() !== end.getDate()) {
        r += ' ' + this.$t('message.oClock') + ' – '
        r += Vue.prototype.$getNullsBefore(end.getDate()) + '.' + Vue.prototype.$getNullsBefore(end.getMonth() + 1) + '.' + end.getFullYear() + ', ' + Vue.prototype.$getNullsBefore(end.getHours()) + ':' + Vue.prototype.$getNullsBefore(end.getMinutes()) + ' ' + this.$t('message.oClock')
      } else if (begin.getHours() !== end.getHours() || begin.getMinutes() !== end.getMinutes()) {
        r += ' – '
        r += Vue.prototype.$getNullsBefore(end.getHours()) + ':' + Vue.prototype.$getNullsBefore(end.getMinutes()) + ' ' + this.$t('message.oClock')
      } else {
        r += ' ' + this.$t('message.oClock')
      }
      return r
    }
    Vue.prototype.$timer = function (date, direction) {
      var seconds

      if (direction === -1) {
        seconds = Math.floor((new Date() - date) / 1000)
      } else {
        seconds = Math.floor((date - new Date()) / 1000)
      }

      if (!seconds) {
        return ' ' + this.$t('message.lessSeconds')
      }

      var interval = Math.floor(seconds / 31536000)

      if (interval === 1) {
        return interval + ' ' + this.$t('message.year')
      }

      if (interval > 1) {
        return interval + ' ' + this.$t('message.years')
      }

      interval = Math.floor(seconds / 2592000)

      if (interval === 1) {
        return interval + ' ' + this.$t('message.month')
      }

      if (interval > 1) {
        return interval + ' ' + this.$t('message.months')
      }

      interval = Math.floor(seconds / 86400)

      if (interval === 1) {
        return interval + ' ' + this.$t('message.day')
      }

      if (interval >= 1) {
        return interval + ' ' + this.$t('message.days')
      }

      interval = Math.floor(seconds / 3600)

      if (interval === 1) {
        return interval + ' ' + this.$t('message.hour')
      }

      if (interval > 1) {
        return interval + ' ' + this.$t('message.hours')
      }

      interval = Math.floor(seconds / 60)

      if (interval === 1) {
        return interval + ' ' + this.$t('message.minute')
      }

      if (interval > 1) {
        return interval + ' ' + this.$t('message.minutes')
      }

      if (Math.floor(seconds) === 1) {
        return seconds + ' ' + this.$t('message.second')
      }

      return Math.floor(seconds) + ' ' + this.$t('message.seconds')
    }
    Vue.prototype.$getLastMonthDays = function (data) {
      var d = new Date()
      d.setHours(0)
      d.setMinutes(0)
      d.setSeconds(0)
      d.setMilliseconds(0)

      var check = new Date()
      check.setMonth(check.getMonth() + 1)
      check.setDate(0)
      check.setHours(0)
      check.setMinutes(0)
      check.setSeconds(0)
      check.setMilliseconds(0)

      var check2 = new Date(check.getTime())
      check2.setDate(check.getDate() - data.days)

      if (d.getTime() < check.getTime() && d.getTime() >= check2.getTime()) {
        data.callback()
        return false
      }
    }
  }
}
