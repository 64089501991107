<template>
  <transition name="flixFadeIn">
    <div class="flix-popup" v-if="open" :key="open">
      <div class="flix-popup-content">
        <div v-if="type === 'warning'" class="flix-popup-icon flix-text-danger"><flixIcon :id="'warning-sign'" /></div>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  components: {},
  props: {
    time: Number,
    type: String
  },
  data () {
    return {
      open: false,
      openTime: 2000
    }
  },
  methods: {

  },
  mounted () {
    if (this.time) {
      this.openTime = this.time
    }
    setTimeout(function () {
      this.open = true
    }.bind(this), 200)
    setTimeout(function () {
      this.open = false
    }.bind(this), 200 + this.openTime)
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>

</style>
