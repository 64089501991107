<template>
  <div>
    <a href="#" @click.prevent="open = !open" class="flix-html-a"><flixIcon id="plus" /> {{ $t('message.add', {name: 'Ordner'}) }}</a>
    <transition name="fadeIn">
      <div class="flix-input-group" :key="open" v-if="open" v-show="!uploading">
        <span class="flix-input-group-addon">
          <flixIcon id="folder-new" />
        </span>
        <input type="text" class="flix-form-control" placeholder="Ordnername" v-model="newFolder">
        <a href="#" @click.prevent="createFolder" class="flix-input-group-addon flix-html-a">
          Ordner erstellen
        </a>
      </div>
    </transition>
    <flixLoader v-if="uploading" />
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    user: Object,
    callback: Function,
    subfolder: String,
    onReady: Function
  },
  data () {
    return {
      open: false,
      newFolder: '',
      folder: 'uploads/' + this.user.ID + '/' + this.subfolder,
      uploading: false
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    createFolder (callback) {
      this.uploading = true
      this.$flix_post({
        url: 'cloud/folder',
        data: {
          user: this.$store.state.user.md5_id,
          folder: this.folder + '/' + this.newFolder + '/',
          action: 'create'
        },
        callback: function (ret) {
          this.uploading = false
          this.onReady()
        }.bind(this)
      })
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
