<template>
  <div class="flix-form-group">
    <b-flix-h3 style="display: block; margin-bottom: 20px">Statistik</b-flix-h3>
    <b-flix-p>
      <flix-statistic :user="user" />
    </b-flix-p>
  </div>
</template>
<script>
export default {
  props: {
    user: {
      type: String,
      default: ''
    }
  },
  components: {}
}
</script>
<style>
a.statistic-btn {
  color: black;
}
</style>
