<template>
<span class="uploadImg">
  <flixLoader v-if="uploading" />
  <image-uploader
    v-show="!uploading"
    :id="data.id"
    :debug="data.debug"
    :maxWidth="data.maxWidth"
    :maxHeight="data.maxHeight"
    :quality="data.quality"
    :autoRotate=data.autoRotate
    :outputFormat="data.outputFormat"
    :preview=data.preview
    :className="data.className"
    :capture="data.capture"
    :accept="data.accept"
    :doNotResize="data.doNotResize"
    @input="data.input"
    @onUpload="data.onUpload"
    @onComplete="data.onComplete"
  >

    <label v-bind:for="data.id" slot="upload-label" class="img-label">
      <img v-if="data.image && data.image.dataUrl" v-bind:src="data.image.dataUrl" class="hit-img-responsive">

    <span v-if="!data.image || !data.image.dataUrl" v-html="data.label"></span>

    </label>

  </image-uploader>

</span>
</template>

<script>

import ImageUploader from 'vue-image-upload-resize'

export default {

  props: { settings: Object },

  components: {
    ImageUploader
  },

  data () {
    return {
      uploading: false,
      data: this.getSettings(),
      hasImage: false,
      ID: new Date().getTime() + Math.random(0, 9)
    }
  },
  methods: {
    getID () {
      return this.ID + ''
    },
    setImage: function (output) {
      this.hasImage = true
      this.data.image = output

      if (typeof this.data.onSave === 'function') { this.data.onSave(output) }
    },

    startImageResize () {
      this.uploading = true
    },
    endImageResize () {
      this.uploading = false
    },
    getSettings () {
      var def = {
        label: '<b>' + this.$t('message.upload', { name: this.$t('message.image') }) + '</b>',
        image: null,
        id: new Date().getTime() + Math.random(0, 9) + '',
        debug: 0,
        maxWidth: 250,
        maxHeight: 250,
        quality: 0.7,
        autoRotate: true,
        outputFormat: 'verbose',
        preview: false,
        className: ['fileinput'],
        capture: 'false',
        accept: 'video/*,image/*',
        doNotResize: "['gif', 'svg']",
        input: this.setImage,
        onUpload: this.startImageResize,
        onComplete: this.endImageResize,
        onSave: false

      }
      if (typeof this.settings === 'object') { def = Object.assign(def, this.settings) }
      return def
    }
  }
}

</script>

<style :scoped>
input[type='file'].fileinput {
  display: none;
}
.img-label{
  cursor: pointer;
}
figure, .uploadImg {
  display: inline-block
}

</style>
