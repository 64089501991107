<template>
  <div>
    <div>
      <strong class="flix-text-info">
        <flixIcon id="folder-open"/> {{ newSubfolder }}
      </strong>
    </div>
    <div v-if="files" :key="updateKey">
      <div v-for="file in files" :key="file.path">
        <span v-if="!file.filetype">
          <span v-if="subfolder != file.path.replace(folder + subfolder, '') && file.path.replace(folder + newSubfolder, '')">
            <deleteFolder :folder="file.path" :callback="onChange" /> <a href="#" class="flix-html-a" @click.prevent="changeSubfolder(file.path.replace(folder, ''))"><flixIcon id="folder-closed" /> {{ file.path.replace(folder + newSubfolder, '') }}</a>
          </span>
          <span v-if="subfolder != file.path.replace(folder + subfolder, '') && !file.path.replace(folder + newSubfolder, '')">
            <a href="#" class="flix-html-a" @click.prevent="changeSubfolder(file.path.replace(folder + newSubfolder, ''))"><flixIcon id="folder-closed" /> ..</a>
          </span>
        </span>
        <span v-else>
          <deleteFolder :folder="file.path" :callback="onChange" /> <a target="_blank" :href="file.href" v-if="subfolder != file.path.replace(folder + subfolder, '')" class="flix-html-a"><flixIcon id="file" /> {{ file.path.replace(folder + newSubfolder, '') }} - <small class="flix-html-small">{{ file.date }}</small></a>
        </span>
      </div>
    </div>
    <div v-else>
      <a href="#" class="flix-html-a" @click.prevent="changeSubfolder('')"><flixIcon id="folder-closed" /> ..</a>
    </div>
    <flixLoader v-if="loading" />
  </div>
</template>
<script>
export default {
  components: {
    deleteFolder () { return import('./deleteFolder') }
  },
  props: {
    user: Object,
    onChange: Function,
    subfolder: String
  },
  data () {
    return {
      folder: 'uploads/' + this.user.ID + '/',
      files: false,
      newSubfolder: JSON.parse(JSON.stringify(this.subfolder)),
      loading: false,
      updateKey: 0
    }
  },
  computed: {

  },
  mounted () {
    this.getFileList()
  },
  methods: {
    deleteFolder (sf) {

    },
    changeSubfolder (sf) {
      this.files = false
      this.newSubfolder = sf
      this.getFileList()
      this.onChange(sf)
    },
    getFileList () {
      this.loading = true
      this.$flix_post({
        url: 'cloud/file',
        data: {
          user: this.$store.state.user.md5_id,
          folder: this.folder + this.newSubfolder
        },
        callback: function (ret) {
          if (ret.data[0]) {
            this.files = ret.data[1]
          }
          this.loading = false
          this.updateKey++
        }.bind(this)
      })
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
