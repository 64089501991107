<template>
  <div v-if="user">
    <div>
      <div class="flix-form-group">
        <b-flix-button variant="text-icon" :callback="setBackUserList">{{
          $t('message.user')
        }}</b-flix-button>
      </div>
      <b-flix-card>
        <div slot="title">
          <b-flix-h1> {{ user.username }}</b-flix-h1>
        </div>

        <div class="flix-form-group">
          <b-flix-list>
            <li>#{{ user.ID }}</li>
            <li>
              <a :href="'mailto:' + user.email">{{ user.email }}</a>
            </li>
            <li>
              {{ user.registration.split(' ').join(', ') }}
              {{ $t('message.oClock') }} ({{ user.status }})
            </li>
            <li>
              <a href="#" class="flix-html-a" @click.prevent="setLogin">{{
                $t('message.signIn')
              }}</a>
            </li>
            <li>
              <router-link
                class="flix-html-a"
                :to="{
                  name: 'cloudDetails',
                  params: { username: this.user.username }
                }"
                >{{
                  $t('message.upload', { name: $tc('message.ressources', 1) })
                }}</router-link
              >
            </li>
          </b-flix-list>
        </div>

        <div class="">
          <membership :user="user" />
        </div>
      </b-flix-card>
      <b-flix-card>
        <div><backups :user="user"></backups></div>
      </b-flix-card>
      <b-flix-card>
        <div>
          <maxCalendar :user="user" />
        </div>
      </b-flix-card>
    </div>
    <div class="">
      <calendar :user="user" />
      <div class="flix-col-md-12">
        <delete :user="user" />
      </div>
    </div>
    <b-flix-card>
      <div>
        <div class="flix-clearfix">
          <b-flix-button
            v-if="!statistic"
            variant="contained-blue"
            :callback="
              () => {
                statistic = true
              }
            "
            >Statistik öffnen</b-flix-button
          >
          <userStatistic
            v-if="statistic"
            :user="user.md5_user"
            :admin="user.md5_user"
          />
        </div>
      </div>
    </b-flix-card>
  </div>
</template>
<script>
export default {
  components: {
    backups: () => import('@/components/dashboard/userDetails/backups'),
    calendar() {
      return import('@/components/dashboard/userDetails/calendar')
    },
    delete() {
      return import('@/components/dashboard/userDetails/delete')
    },
    maxCalendar() {
      return import('@/components/dashboard/userDetails/max_calendar')
    },
    userStatistic() {
      return import('@/components/dashboard/userStatistic/')
    },
    membership() {
      return import('@/components/dashboard/userDetails/membership/')
    }
  },
  props: {},
  data() {
    return {
      user: false,
      statistic: false
    }
  },
  methods: {
    setBackUserList () {
      this.$router.push({ name: 'userList', params: {} })
    },
    setLogin() {
      var data = {
        redirect: this.$flix_url + '/dashboard.html',
        user: this.user.md5_user,
        date: new Date().getTime()
      }
      var url =
        this.$flix_url +
        '/admin/redirect/' +
        btoa(JSON.stringify(data)) +
        '.html'
      window.open(url)
    },
    getUserData() {
      this.$store.state.users.forEach(
        function (user) {
          if (user.username === this.$route.params.username) {
            this.user = user
          }
        }.bind(this)
      )
    }
  },
  mounted() {
    this.getUserData()
  },
  computed: {}
}
</script>
<style lang="sass" scoped></style>
