<template>
  <div>
    <div class="flix-container-fluid">
      <userStats />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    userStats () { return import('@/components/dashboard/stats/user') }
  },
  props: {},
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {
    this.$store.dispatch('getUsers')
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
