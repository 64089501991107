<template>
  <div v-if="backups">
    <div class="flix-text-center">
      <b-flix-h2 variant="green" style="display: block; margin-bottom: 20px"
        >Letzte Backup-Aktivitäten ({{ backups.length }})</b-flix-h2
      >
    </div>
    <b-flix-list
      variant="number"
      style="display: flex; align-items: center; justify-content: center"
    >
      <li v-for="user in backups" :key="user.ID">
        <strong>{{ getUsername(user.user) }} </strong>
        <div
          style="
            text-transform: uppercase;
            display: flex;
            align-items: center;
            gap: 5px;
          "
        >
          <b-flix-icon
            v-if="user.status === 'planing'"
            icon="clock"
          /><b-flix-icon v-else icon="circle-empty-check" /> Backup
          {{ user.status }}
          <bookingDate
            class="flix-text-danger"
            style="
              text-transform: uppercase;
              display: flex;
              align-items: center;
              gap: 5px;
            "
            :date="user.date"
          />
        </div>
        <router-link
          :to="{
            name: 'userDetails',
            params: { username: getUsername(user.user) }
          }"
          :class="{ 'flix-active': user.status === 'validated' }"
        >
          Zum Account
        </router-link>
      </li>
    </b-flix-list>
  </div>
</template>
<script>
import axios from 'axios'
var qs = require('qs')
export default {
  name: 'defaultComponent',
  components: {
    bookingDate() {
      return import('@/components/dashboard/pageStatistic/date')
    }
  },
  props: {},
  data() {
    return {
      backups: false
    }
  },
  computed: {},
  mounted() {
    this.getBackups()
  },
  methods: {
    async getBackups() {
      const post = axios.create()
      await post
        .post(
          'https://backupserver.bookingflix.com/api/status',
          qs.stringify({
            token: process.env.VUE_APP_BACKUP_TOKEN
          })
        )
        .then((ret) => {
          this.backups = ret.data[1]
        })
    },
    getUsername(id) {
      const user = this.$store.state.users.filter((user) => {
        if (id * 1 === user.ID * 1) {
          return user
        }
        return false
      })

      return user[0] ? user[0].username : 'gelöschter User #' + id
    }
  }
}
</script>
<style lang="sass" scoped></style>
