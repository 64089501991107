<template>
  <b-flix-menu style="z-index: 900" :key="$route.name">
    <ul>
      <li class="brand"><b-flix-logo variant="white"/></li>
      <li><router-link :to="{name: 'dashboard'}"> {{ $tc("route.dashboard", 2) }}</router-link></li>
      <li><router-link :to="{name: 'userList'}"> {{ $tc("route.userList", 2) }}</router-link></li>
      <li><router-link :to="{name: 'pageStatistic'}"> {{ $tc("route.pageStatistic", 2) }}</router-link></li>
      <li><router-link :to="{name: 'reminderErrors'}"> {{ $tc("route.reminderErrors", 2) }}</router-link></li>
      <li><router-link :to="{name: 'deleteUnvalidated'}">{{ $tc("route.deleteUnvalidated", 2) }}</router-link></li>
      <li><router-link :to="{name: 'supportChat'}">{{ $tc("route.supportChat", 2) }}</router-link></li>
      <li><router-link :to="{name: 'signOut'}">{{ $tc("route.signOut", 2) }}</router-link></li>
    </ul>
  </b-flix-menu>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      toggleMenu: true
    }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
  <style scoped lang="sass">
    @import '@/assets/style/sass/stylesheet/flix_color_style.scss'
    .flix-navbar-brand
      display: block
      height: inherit
      width: 200px
      .logo
        height: 40px
        margin-bottom: 20px
        display: block
    @media(max-width: 500px)
      .flix-navbar-brand
        display: block
        height: inherit
        width: 100%
        .logo
          display: block
          width: 100%
          clear: both
    .router-link-exact-active
      border-bottom: 2px solid #fff
      &.flix-navbar-brand
        border-bottom: 0
  </style>
