<template>
  <div style="display: flex; width: 100%; align-items: center; justify-content: center; padding: 0;">
    <b-flix-card>
      <div slot="header">
        <b-flix-logo/>
      </div>
      <div slot="title" variant="gray" style="text-align: center">
      </div>
      <div slot="content" variant="gray">
        <slot name="content">
          <form @submit.prevent="sendData">
            <div class="form-element">
              <b-flix-input type="text" placeholder="Username eingeben" label="Username" :callback="setUsername" info=""/>
            </div>
            <div class="form-element">
              <b-flix-input type="password" placeholder="Passwort eingeben" label="Passwort" :callback="setPassword" info=""/>
            </div>
            <div class="form-element">
              <b-flix-button variant="contained-green" :callback="sendData">Jetzt einloggen</b-flix-button>
            </div>
            <button type="submit" style="display: none" />
          </form>
        </slot>
        <b-flix-alert variant="danger" v-if="error">{{ error }}</b-flix-alert>
      </div>
      <div slot="footer" variant="gray" style="text-align: center">
          <b-flix-button variant="text" link="https://web.bookingflix.com">Zu Bookingflix</b-flix-button>
      </div>
    </b-flix-card>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      username: '',
      pass: '',
      error: false
    }
  },
  methods: {
    setUsername (u) {
      this.username = u
    },
    setPassword (p) {
      this.pass = p
    },
    sendData () {
      this.$flix_post({
        url: 'user/get_login',
        data: {
          username: this.username,
          password: this.pass
        },
        callback: function (ret) {
          this.error = false
          if (!ret.data[0]) {
            this.error = 'Sorry. Login hat nicht funktioniert.'

            setTimeout(function () {
              this.error = false
            }.bind(this), 3000)

            this.$store.commit('setLogin', false)
            return false
          }
          this.$store.commit('setLogin', ret.data[1])
          this.$router.push({ name: 'dashboard' })
        }.bind(this)
      })
    }
  },
  mounted () {
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .form-element
    margin-bottom: 20px
</style>
