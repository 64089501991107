<template>
  <div class="flix-container">
    <b-flix-h1 style="margin-bottom: 20px; display: block;">Warum werden keine System-Nachrichten (Zusage, Absage, Erinnerungen) gesendet?</b-flix-h1>
    <searchForm :users="allUsers" :callback="function (s) { setSearch(s) }"/>
    <b-flix-h2>Datensatz: {{ (page - 1) * breakpoint + 1 }} - {{ total }} von {{ allUsers.length }}</b-flix-h2>
    <getMenu :key="pages + '1'" :page="page" :pages="pages" :callback="setStart" />

    <b-flix-viewportload v-for="(user, key) in users" :key="user.ID" :callback="setViewport">
      <b-flix-layout variant="main-page" :settings="getSettings(key)">
        <div class="flix-list-group">
          <h3 class="flix-html-h4">{{ user.error }}</h3>
          <div class="flix-flex">
            <small><flixIcon id="calendar" /> {{ user.date }}</small>
            <small><a href="#" @click.prevent="getUser(user.user)" class="flix-btn flix-btn-default flix-btn-xs"><flixIcon id="user" /> {{ user.user }}</a></small>
            <small>Buchungs-ID <b>#{{ user.user }}-{{ user.client }}</b></small>
            <small>Versandnr. (SMS / Email) <b>#{{ user.automailer }}</b></small>
          </div>
        </div>
      </b-flix-layout>
    </b-flix-viewportload>

    <getMenu :key="pages + '2'" :page="page" :pages="pages" :callback="setStart" />
  </div>
</template>
<script>
export default {
  components: {
    searchForm () { return import('@/components/dashboard/reminderErrors/search') },
    getMenu () { return import('@/components/dashboard/userList/menu') }
  },
  props: {},
  data () {
    return {
      allUsers: false,
      page: 1,
      breakpoint: 12,
      pages: 0,
      users: false,
      total: 0
    }
  },
  methods: {
    setViewport (obj) {
      obj.entry.target.style.opacity = obj.opacity
      obj.entry.target.style.transform = 'scale(' + obj.opacity + ')'
    },
    getSettings (key) {
      if (key % 2 === 0) {
        return {
          style: 'gray-dark',
          arrow: false,
          shadow: false
        }
      }
      return {
        style: 'gray',
        arrow: false,
        shadow: false
      }
    },
    getUser (ID) {
      this.$store.commit('setSearch', ID)
      this.$router.push({ name: 'userList' })
    },
    getTotal (l) {
      var total = (this.page) * this.breakpoint
      if (l.length < total) {
        return l.length
      }
      return total
    },
    setStart (i) {
      this.page = i
      this.users = this.getUsers(this.allUsers)
      this.total = this.getTotal(this.allUsers)
    },
    setSearch (s) {
      this.users = this.getUsers(s)
      this.total = this.getTotal(s)
      this.page = 1
      this.pages = Math.ceil(s.length / this.breakpoint)
    },
    getUsers (u) {
      var user = []
      for (var i = ((this.page * this.breakpoint) - this.breakpoint); i < (this.page * this.breakpoint); i++) {
        if (typeof u[i] !== 'undefined') {
          user.push(u[i])
        }
      }
      return user
    }
  },
  mounted () {
    this.$flix_post({
      url: 'reminder/get_errors',
      data: {
        user: this.$store.state.user.md5_id
      },
      callback: function (ret) {
        this.allUsers = ret.data[1]
        this.users = this.getUsers(ret.data[1])
        this.total = this.getTotal(ret.data[1])
        this.pages = Math.ceil(ret.data[1].length / this.breakpoint)
      }.bind(this)
    })
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .icon
    font-size: 25pt
    margin-bottom: 5px
  .flix-flex
    display: flex
    align-items: center
    justify-content: flex-start
    gap: 10px
</style>
