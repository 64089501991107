<template>
  <div>
    <b-flix-h5 style="display: block; margin-bottom: 10px;">Mitgliedschaft ändern</b-flix-h5>
    <div class="flix-form-group">
      <b-flix-button variant="text" :callback="function () { setMembership('+ 1 months') }">Monats-Abo</b-flix-button>
      <b-flix-button variant="text" :callback="function () { setMembership('+ 1 years') }">Jahres-Abo</b-flix-button>
      <b-flix-button variant="text" href="#" class="flix-btn flix-btn-danger" :callback="function () { setMembership('+ 0 days') }">Free-Mitgliedschaft</b-flix-button>
    </div>
    <div class="flix-form-group" v-if="membership.details.auto">
      <b-flix-radio style="margin-bottom: 20px" :values="{1: 'automatische Verlängerung aktiv'}" :value="1">
      </b-flix-radio>
    </div>

    <b-flix-alert variant="success" v-if="success">{{ $t('message.successfullSaved') }}</b-flix-alert>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    membership: Object,
    user: Object,
    callback: Function
  },
  data () {
    return {
      success: false,
      saved: false
    }
  },
  computed: {

  },
  mounted () {
  },
  methods: {
    setMembership (time) {
      this.$flix_post({
        url: 'user/update_membership',
        data: {
          user: this.user.md5_user,
          data: {
            membership: time
          }
        },
        callback: function () { this.setSuccess(time) }.bind(this)
      })
    },
    setSuccess (time) {
      this.success = true
      this.saved = time
      setTimeout(function () {
        this.success = false
        this.callback()
      }.bind(this), 2000)
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
