<template>
  <div class="flix-html-html">
    <dashboard />
  </div>
</template>

<script>

import dashboard from '@/components/index.vue'

export default {

  components: { dashboard }
}
</script>
<style lang="sass">
  @import url('../node_modules/bookingflix_framework/dist/lib/src/bflix.css')
  html, html > body
    margin: 0 !important
    padding: 0 !important
</style>
