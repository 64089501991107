<template>
  <div>
    <navbar v-if="$store.state.user" />
    <b-flix-layout variant="main-header">
      <b-flix-h1 variant="white">Bookingflix</b-flix-h1>
      <b-flix-h2 variant="white" style="display: block; margin-top: 20px">{{ $t('route.' + $route.name) }}</b-flix-h2>
    </b-flix-layout>
    <transition name="fadeIn">
      <b-flix-layout variant="main-page" :settings="layoutSettings">
        <router-view />
      </b-flix-layout>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    navbar () { return import('@/components/dashboard/navigation/') }
  },
  props: {},
  data () {
    return {
      layoutSettings: { arrow: false, style: 'white', shadow: false }
    }
  },
  methods: {

  },
  mounted () {
    this.$store.commit('getLogin')
    if (!this.$store.state.user) {
      this.$router.push({ name: 'signIn' })
    } else {
      this.$router.push({ name: 'dashboard' })
    }
  },
  computed: {

  }
}
</script>
<style lang="sass">
  @import '@/assets/style/sass/stylesheet/flix_color_style.scss'
  @import '@/assets/style/sass/stylesheet/flix_bootstrap_style.scss'

  .fadeIn-enter .fadeIn-leave
    opacity: 0
    transform: scaleY(0)
    transform-origin: top

  .fadeIn-enter-active
    animation: fadeIn 0.3s ease-in-out
    transform-origin: top
    opacity: 0
    animation-delay: 0.3s

  .fadeIn-leave-active
    animation: fadeIn 0.3s reverse
    transform-origin: top

  @keyframes fadeIn
    from
      opacity: 0
      transform: scaleY(0)
    to
      opacity: 1
      transform: scaleY(1)
</style>
