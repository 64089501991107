<template>
  <span style="display: block;">
    <flixLoader v-if="loadData"/>
    <b-flix-button variant="text" v-else :callback="setDownloadCSV"><flixIcon id="download" /> CSV</b-flix-button>
    <div style="display: block; text-align: center">
      <b-flix-alert variant="danger" style="display: inline-flex; align-items: center; justify.content: center; margin-top: 20px;" v-if="noData">{{ $tc('message.noEntry', 2) }}</b-flix-alert>
    </div>
  </span>
</template>
<script>
import csv from './csv.js'
export default {
  components: {},
  props: {
    calendar: Object,
    user: Object
  },
  data () {
    return {
      loadData: false,
      noData: false
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setNoData () {
      this.noData = true
      this.loadData = false
      setTimeout(function () {
        this.noData = false
      }.bind(this), 2000)
    },
    forceFileDownload (response) {
      this.date = new Date()
      const url = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', this.$getNullsBefore(this.date.getFullYear()) + '-' + this.$getNullsBefore((this.date.getMonth() + 1)) + '-' + this.$getNullsBefore(this.date.getDate()) + '-' + this.$getNullsBefore(this.date.getHours()) + '-' + this.$getNullsBefore(this.date.getMinutes()) + '-' + this.$getNullsBefore(this.date.getSeconds()) + '-' + this.$flix_brand.toLowerCase() + '-' + this.user.username + '-' + this.calendar.ID + '.csv')
      document.body.appendChild(link)
      link.click()
      this.loadData = false
    },
    setHandleDownload (data) {
      if (!data.length) {
        this.setNoData()
        return false
      }
      var c = new csv()
      c.data = data
      c.filter = 'all'

      c.parent = this
      c.get()
      this.forceFileDownload(c.csv)
    },
    setDownloadCSV () {
      this.loadData = true
      this.$flix_post({
        url: 'booking/get',
        data: {
          user: this.user.md5_user,
          ID: this.calendar.ID,
          options: {
            future: true
          }
        },
        callback: function (ret) { this.setHandleDownload(ret.data[1]) }.bind(this)
      })
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
