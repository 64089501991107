<template>
  <span>
    <span v-if="!loading">
      <span>
        <a href="#" @click.prevent="open = !open" class="flix-text-danger flix-html-small" ><flixIcon id="bin" /></a>
      </span>
      <span v-if="open">
        &nbsp; <a href="#" class="flix-html-a" @click.prevent="deleteFolder()">{{ $t('message.yes') }}</a> | <a href="#" class="flix-html-a" @click.prevent="open = !open">{{ $t('message.no') }}</a>
      </span>
    </span>
    <flixLoader v-if="loading" />
  </span>
</template>
<script>
export default {
  components: {},
  props: {
    folder: String,
    callback: Function
  },
  data () {
    return {
      open: false,
      loading: false
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    deleteFolder () {
      this.loading = true
      this.$flix_post({
        url: 'cloud/folder',
        data: {
          user: this.$store.state.user.md5_id,
          folder: this.folder,
          action: 'delete'
        },
        callback: function (ret) {
          this.loading = false
          this.callback()
        }.bind(this)
      })
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
