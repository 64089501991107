import Vue from 'vue'
import VueRouter from 'vue-router'
import { dashboard } from '@/router/dashboard.js'

Vue.use(VueRouter)

var routes = [{
  path: '/',
  name: 'signIn',
  component: () => import('@/components/signIn')
}]

routes = routes.concat(dashboard)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
