<template>
  <div>
    <div v-if="!loaded" style="display: flex; align-items: center; justify-content: center;">
      <b-flix-loader size="lg"/>
    </div>
    <div v-else>
      <flixIcon :id="'important-day'" class="icon" />
      <b-flix-h1>{{ bookings }}</b-flix-h1>
      <b-flix-p>Gesamtzahl aller Terminbuchungen</b-flix-p>
      <div>
        <b-flix-p>
        <a href="#" class="flix-html-a" v-if="checkDate()" @click.prevent="setDate(-1)"><flixIcon id="chevron-left" /></a>
        {{ date.getDate() }}.{{ date.getMonth() + 1 }}.{{ date.getFullYear() }}
        <a href="#" class="flix-html-a" v-if="index < 0" @click.prevent="setDate(+1)"><flixIcon id="chevron-right" /></a>
        </b-flix-p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      bookings: 0,
      date: this.getDate(0),
      index: 0,
      loaded: false
    }
  },
  methods: {
    checkDate () {
      var check = new Date()
      check.setYear(2021)
      check.setMonth(1)
      check.setDate(12)
      check.setHours(0)
      check.setMinutes(0)
      check.setSeconds(0)
      check.setMilliseconds(0)

      if (check.getTime() > this.date) {
        return false
      }
      return true
    },
    setDate (i) {
      this.loaded = false
      this.index = this.index + i
      this.date.setDate(this.date.getDate() + i)
      this.getBookings()
    },
    getDate (i) {
      var d = new Date()
      d.setDate(d.getDate() + i)
      return d
    },
    getBookings () {
      var d = new Date(this.date)
      d.setDate(d.getDate() + 1)
      this.$flix_post({
        url: 'statistic/get_total_bookings',
        data: {
          date: d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()
        },
        callback: function (ret) {
          if (ret.data[0]) { this.bookings = this.mach_tausender((ret.data[1] * 1)) }
          this.loaded = true
        }.bind(this)
      })
    },
    mach_tausender (zahl) {
      zahl = Math.round(zahl)
      if (zahl < 1000) {
        return zahl
      }
      zahl = zahl + ''
      var i
      var j = 0
      var ergebnis = ''

      i = zahl.length
      while (i >= 0) {
        ergebnis = zahl.substr(i, 1) + ergebnis
        j++
        if (j === 4) {
          ergebnis = '.' + ergebnis
          j = 1
        }
        i--
      }
      if (ergebnis[0] === '.') {
        ergebnis = ergebnis.replace('.', '')
      }
      return ergebnis
    }
  },
  mounted () {
    this.getBookings()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .count
    font-size: 40pt
    font-weight: bold
  .icon
    font-size: 40pt
    display: block
    margin-bottom: 5px
</style>
