<template>
  <div>
    <a href="#" @click.prevent="open = !open" class="flix-html-a"><flixIcon id="plus" /> {{ $t('message.add', {name: 'Upload'}) }}</a>
    <transition name="fadeIn">
      <div :key="open">
      <form method="post" enctype="multipart/form-data" v-on:change="setData" id="form" v-show="!uploading" v-if="open">
        <div class="flix-form-group">
          <h3 class="flix-html-h3">Upload {{ subfolder }}</h3>
          <input type="hidden" name="user" :value="$store.state.user.md5_id" />
          <input type="file" class="flix-form-control" name="upload" id="file"/>
        </div>
      </form>
    </div>
    </transition>
    <flixLoader v-if="uploading"/>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  components: {
  },
  props: {
    user: Object,
    subfolder: String,
    onReady: Function
  },
  data () {
    return {
      open: false,
      folder: 'uploads/' + this.user.ID + '/' + this.subfolder,
      uploading: false
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setData () {
      this.uploading = true
      this.createFolder(this.uploadFile)
    },
    createFolder (callback) {
      this.$flix_post({
        url: 'cloud/folder',
        data: {
          user: this.$store.state.user.md5_id,
          folder: this.folder,
          action: 'create'
        },
        callback: function (ret) {
          callback()
        }
      })
    },
    uploadFile () {
      var formData = new FormData()
      var imagefile = document.querySelector('#file')
      formData.append('user', this.$store.state.user.md5_id)
      formData.append('folder', this.folder)
      formData.append('upload', imagefile.files[0])
      axios.post('https://api.bookingflix.com/de/cloud/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(function (data) {
          this.uploading = false
          this.onReady()
        }.bind(this))
        .catch(function (data) {
          this.uploading = false
        })
    },
    setImage: function (output) {
      window.console.log(output)
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
