import Vue from 'vue'
import * as FlixModule from 'bookingflix_framework'
import CalendarItems from 'bookingflix_calendar_items'

Object.keys(FlixModule).forEach(function (r) {
  const name = r.replace('Flix', '').toLowerCase()
  Vue.component('b-flix-' + name, FlixModule[r])
})

window.Vue = Vue

Vue.use(CalendarItems)
