<template>
  <div>
    <div class="flix-container">
      <flixLoader v-if="loading" :text="'lade Chats'"/>
      <div v-else>
        <div class="flix-form-group">
          <a href="#" @click.prevent="function(){ filter = 'open' }" class="flix-btn flix-btn-default" :class="{'flix-btn-success': filter === 'open'}">Unbeantwortete Supportchats</a>
          <a href="#" @click.prevent="function(){ filter = 'answered' }" class="flix-btn flix-btn-default" :class="{'flix-btn-success': filter === 'answered'}">Beantwortete Supportchats</a>
          <a href="#" @click.prevent="function(){ filter = 'all' }" class="flix-btn flix-btn-default" :class="{'flix-btn-success': filter === 'all'}">Alle Supportchats</a>
        </div>
        <div class="flix-form-group">
          <a href="#" @click.prevent="setReload" class="flix-btn flix-btn-default">Supportchats aktualisieren</a>
        </div>
        <div class="flix-form-group">
          <div class="flix-list-group">
            <div :key="filter">
              <a :href="getLink(chat)" target="_blank" class="flix-html-a flix-list-group-item" :class="getClass(chat, filter)" v-for="chat in data" :key="chat.ticket">
                <div class="flix-form-group">
                  <span v-html="chat.date" />
                </div>
                <div class="flix-form-group">
                  <i><b v-html="getChatText(chat)" /></i>
                </div>
                <div class="flix-form-group">
                  <span class="flix-pull-right">
                    <deleteChat :callback="function() { deleteChat(chat) }" :chat="chat" />
                  </span>
                  <a :href="'mailto:'+chat.email" class="flix-btn flix-btn-default flix-btn-xs"><flixIcon id="envelope" /> {{ chat.email }}</a>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    deleteChat () { return import('@/components/dashboard/supportChat/delete') }
  },
  props: {},
  data () {
    return {
      filter: 'open',
      data: [],
      loading: true
    }
  },
  watch: {
  },
  methods: {
    getChatText (chat) {
      var r = ''
      if (chat.user === 'user') {
        r += 'User: '
      } else {
        r += 'Support: '
      }
      r += ': '
      r += '"'
      r += this.$nl2br(chat.text)
      r += '"'
      return r
    },
    setReload () {
      this.loading = true
      this.filter = ''
      this.getChats()
      this.filter = 'open'
    },
    deleteChat (chat) {
      var ticket = chat.ticket

      this.$flix_post({
        url: 'support_chats/delete',
        data: {
          user: this.$store.state.user.md5_id,
          data: [ticket],
          onlyMessages: true
        },
        callback: function (ret) {
          var index = false
          this.data.forEach(function (c, key) {
            if (c.ticket === ticket) {
              index = key
            }
          })
          this.data.splice(index, 1)
        }.bind(this)
      })
    },
    getClass (chat, filter) {
      if (filter === 'open' && chat.user === 'bot') {
        return 'inactive'
      } else if (filter === 'answered' && chat.user === 'user') {
        return 'inactive'
      }
    },
    getLink (chat) {
      return 'https://support.bookingflix.com/de/' + chat.ticket + '/' + btoa(this.$store.state.user.ID) + '.html'
    },
    getChats (filter) {
      this.$flix_post({
        url: 'newscenter/view/supportchats_all',
        data: {
          list: 'all',
          user: this.$store.state.user.md5_id
        },
        callback: function (ret) { this.data = ret.data[1]; this.loading = false }.bind(this)
      })
    }
  },
  mounted () {
    this.getChats(this.filter)
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .inactive
    display: none
</style>
