<template>
  <div>
    <div v-if="!$slots.expand">
      <slot name="toggle"></slot>
    </div>
    <div v-else>
      <div class="flix-form-group">
        <a href="#" :class="getBtnClass()" @click.prevent="open = !open">
          <slot name="button">
            <span v-if="!open" v-html="'mehr'"/>
            <span v-else v-html="'weniger'"/>
          </slot>&nbsp;
          <flixIcon :id="'chevron-down'" v-if="open" />
          <flixIcon :id="'chevron-up'" v-else />
        </a>
      </div>
      <transition name="flixFadeIn">
        <div class="flix-form-group" :key="open">
          <slot name="toggle" v-if="!open"></slot>
          <slot name="expand" v-else></slot>
        </div>
      </transition>
      <div class="flix-form-group" v-if="!settings || typeof settings.bottomButton != 'boolean' || settings.bottomButton !== false">
        <a href="#" :class="getBtnClass()" @click.prevent="open = !open">
          <slot name="button">
            <span v-if="!open" v-html="'mehr'"/>
            <span v-else v-html="'weniger'"/>
          </slot>&nbsp;
          <flixIcon :id="'chevron-down'" v-if="open" />
          <flixIcon :id="'chevron-up'" v-else />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: { settings: Object, callback: Function },
  data () {
    return {
      open: false
    }
  },
  methods: {
    getBtnClass () {
      if (typeof this.settings === 'object' && typeof this.settings.buttonClass !== 'undefined') {
        return this.settings.buttonClass
      }
      return 'flix-btn flix-btn-default'
    }
  },
  mounted () {
    if (typeof this.settings === 'object' && typeof this.settings.open !== 'undefined') {
      this.open = this.settings.open
    }
  }
}
</script>
