<template>
  <div v-if="$store.state.users">
  <div class="flix-row">
    <div class="flix-col-md-12" style="max-height: 220px; display: block; position: relative;">
      <div>
        <b-flix-card style="display: block; padding: 0; text-align: center;">
          <div slot="header"/>
          <div slot="title"/>
          <div slot="content" style="position: relative; padding: 0;">
            <registrationChart :key="JSON.stringify($store.state.users)"/>
            <div class="flix-html-small flix-text-danger">{{ $t('message.signUp') }}</div>
          </div>
        </b-flix-card>
      </div>
    </div>
    <div class="flix-clearfix" />
    <div class="flix-col-md-12">
      <div style="margin-bottom: 30px; text-align: center;">
        <b-flix-card>
          <div slot="header"/>
          <div slot="title"/>
          <div slot="content">
            <totalBookings />
          </div>
        </b-flix-card>
      </div>
    </div>
    <div class="flix-col-md-6">
      <div class="flix-text-center" style="margin-bottom: 30px; text-align: center;">
        <b-flix-card>
            <div slot="header"/>
            <div slot="title"/>
            <div slot="content">
              <flixIcon :id="'user'" class="icon" />
              <b-flix-h1>{{ $store.state.users.length }}</b-flix-h1>
              <b-flix-p>{{ $t('message.user') }}</b-flix-p>
          </div>
        </b-flix-card>
      </div>
    </div>
    <div class="flix-col-md-6">
      <div class="flix-text-center">
        <b-flix-card>
            <div slot="header"/>
            <div slot="title"/>
            <div slot="content">
              <validationCount :key="JSON.stringify($store.state.users)"/>
          </div>
        </b-flix-card>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  components: {
    validationCount () { return import('@/components/dashboard/stats/validationCount') },
    registrationChart () { return import('@/components/dashboard/stats/registrationChart') },
    totalBookings () { return import('@/components/dashboard/stats/totalBookings') }
  },
  props: {},
  data () {
    return {
    }
  },
  methods: {
  },
  mounted () {
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .count
    font-size: 40pt
    font-weight: bold
  .icon
    font-size: 40pt
    display: block
    margin-bottom: 5px
</style>
