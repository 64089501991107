<template>
  <div class="flix-well">
    <div class="flix-row">
      <div class="flix-col-md-12">
        <form @submit.prevent="setSearch">
          <div class="flix-form-group">
            <b-flix-input label="User-ID" type="text" :value="search" :callback="setSearchString" />
          </div>
          <div class="flix-form-group">
            <b-flix-button variant="contained-green" :callback="setSearch">{{ $t('message.search') }}</b-flix-button>
            <button type="submit" style="display: none;"></button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    callback: Function,
    users: [Array, Boolean]
  },
  mounted () {
  },
  computed: {

  },
  data () {
    return {
      search: ''
    }
  },
  methods: {
    setSearchString (s) {
      this.search = s
    },
    setSearch () {
      var search = this.search

      if (!search) {
        this.callback(this.users)
        return false
      }
      var r = []
      this.users.forEach(function (user) {
        if (user.client === search.toLowerCase()) {
          r.push(user)
        } else if (user.user === search) {
          r.push(user)
        }
      })

      this.callback(r)
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
