<template>
  <div>
    <div v-if="!success">
      <div class="flix-form-group">
        <a href="#" class="flix-btn flix-btn-block flix-btn-danger" @click.prevent="setDelete">{{ $t('message.delete') }} {{ filteredUsers.length }} {{ $tc('message.customer', filteredUsers.length) }} ({{ $t('message.allDataWillBeDeleted') }})</a>
      </div>
      <div class="flix-form-group">
        <flixIcon id="info-sign" /> Löschregel: Alle User, die ihren Account nicht validiert haben innerhalb von 48 Stunden nach Registrierung. Mit Klick auf "nein" wird dieser User bei der Sammellöschung ausgespart.
      </div>
      <div class="flix-list-group">
        <div v-for="(user, index) in filteredUsers" :key="user.ID" class="flix-list-group-item ">
          <div class="flix-row">
            <div class="flix-col-md-2">
              <a href="#" @click.prevent="removeDelete(index)" class="flix-html-a flix-text-danger flix-html-small"><flixIcon id="remove" /> {{ $t('message.no') }}</a>
            </div>
            <div class="flix-col-md-10">
              <div># {{ user.ID }} <b><flixIcon id="user" /> {{ user.username }}</b> <flixIcon id="envelope" /> {{ user.email }}</div>
              <small><flixIcon id="calendar" /> {{ user.registration }} <flixIcon id="remove" /> {{ user.status }}</small>
              <div><router-link :to="{name: 'userDetails', params: {username: user.username}}" class="flix-html-a"><flixIcon id="link" /> {{ $t('message.goTo', {name: user.username}) }}</router-link></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flix-alert flix-alert-success">
      {{ $t('message.successfullSaved') }}
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    users: Array
  },
  data () {
    return {
      filteredUsers: JSON.parse(JSON.stringify(this.users)),
      success: false
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    sendDelete (user) {
      this.$flix_post({
        url: 'user/delete_user',
        data: {
          user: user.md5_user
        },
        callback: function () {}
      })
    },
    removeDelete (index) {
      this.filteredUsers.splice(index, 1)
    },
    setDelete () {
      this.success = true
      var IDs = []
      this.filteredUsers.forEach(function (u) {
        IDs.push(u.ID)
      })

      var newUsers = []
      this.$store.state.users.forEach(function (u) {
        if (IDs.indexOf(u.ID) === -1) {
          newUsers.push(u)
        } else {
          this.sendDelete(u)
        }
      }.bind(this))
      this.$store.commit('setUsers', newUsers)
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
