<template>
  <a href="#" class="flix-btn flix-btn-success" :class="{'flix-btn-block': settings && settings.block}" @click.prevent="onClick()"><slot name="icon"><flixIcon class="icon" :id="'ok-sign'" /></slot> <slot name="text">{{ $t('message.save', {name: ''}) }}</slot></a>
</template>
<script>
export default {
  components: {},
  props: {
    onClick: Function,
    settings: Object
  },
  data () {
    return {
    }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  @import '@/assets/style/sass/flixDefaultBtn.sass'
</style>
