import Vue from 'vue'
import App from './App.vue'
import VueI18n from 'vue-i18n'
import router from './router'
import store from './store'
import plugin from './plugin/'
import i18n from './language'
import CKEditor from '@ckeditor/ckeditor5-vue'

require('@/plugin/bf-framework.js')
Vue.config.productionTip = false

Vue.use(CKEditor)
Vue.use(VueI18n)
Vue.use(require('v-chartist'), { noData: {} })

Vue.use(plugin, {
  post_url: 'https://api.bookingflix.com',
  url: 'https://bookingflix.com',
  brand: 'Bookingflix',
  lang: 'de'
})

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#flix_dashboard')
