<template>
  <i class="flix-glyphicon" v-bind:class="get_class()" ></i>
</template>

<script>

export default {

  props: { id: String, defaults: String },

  methods: {
    get_class: function () {
      if (this.defaults) {
        if (this.defaults === 'board') { return { 'flix-glyphicon-group-chat': true } }
      }

      var r = {}
      r['flix-glyphicon-' + this.id] = true

      return r
    }
  }

}

</script>
