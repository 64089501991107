<template>
  <div>
    <div v-if="!loaded" style="display: flex; align-items: center; justify-content: center;">
      <b-flix-loader size="lg" />
    </div>
    <div v-else-if="users">
      <div class="flix-row">
        <div class="flix-col-md-12 flix-text-center">
          <b-flix-h2 variant="green" style="display: block; margin-bottom: 20px">Die grö&szlig;ten Accounts ({{ users.length }})*</b-flix-h2>
        </div>

        <b-flix-table>
          <slot slot="header">
            <tr>
              <td>
                Username
              </td>
              <td>
                Gesamte Buchungen
              </td>
              <td>
                Aktuelle Buchungen
              </td>
              <td>
                Anz. Kalender
              </td>
              <td>
                Aktualisierung
              </td>
              <td>
                Link
              </td>
            </tr>
          </slot>
          <slot slot="content">
            <tr v-for="user in users" :key="user.ID">
              <td>
                {{ user.username }}
              </td>
              <td>
                {{ user.bookings.split(', ')[0].split(': ')[1] * 1 }}
              </td>
              <td>
                {{ user.bookings.split(', ')[1].split(': ')[1] * 1 }}
              </td>
              <td>
                {{ user.assistents }}
              </td>
              <td>
                <span style="display: none;">{{ user.date.split('-').join('') }}</span><bookingDate :date="user.date + ' 00:00:00'" />
              </td>
              <td>
                <router-link  :to="{name: 'userDetails', params: {username: user.username}}" :class="{'flix-active': user.status === 'validated'}">
                  Account
                </router-link>
              </td>
            </tr>
          </slot>
        </b-flix-table>

        <div class="flix-col-md-12">
          <div class="flix-list-group">
            <flixIcon id="info-sign" /> * Beginn der Datenerfassung: 06.10.2020<br><br>Datenaktualisierung erfolgt je Kundenaccount, wenn eine neue Terminbuchung im System eingeht oder wenn ein Termin-Kalender vom Terminverwalter gespeichert / neu angelegt wird.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    bookingDate () { return import('@/components/dashboard/pageStatistic/date') }
  },
  props: {},
  mounted () {
    this.getData()
  },
  computed: {

  },
  data () {
    return {
      users: false,
      loaded: false
    }
  },
  methods: {
    getData () {
      this.$flix_post({
        url: 'statistic/get_big_accounts',
        data: {
          user: this.$store.state.user.md5_id
        },
        callback: function (ret) {
          if (ret.data[0]) {
            this.users = ret.data[1]
          }
          this.loaded = true
        }.bind(this)
      })
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
