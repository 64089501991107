<template>
  <div>
    <div class="flix-container" v-if="user">
      <div class="flix-row">
        <div class="flix-col-md-4">
          <div class="flix-form-group flix-well">
            <shareFolder :user="user" />
          </div>
        </div>
        <div class="flix-col-md-8" :key="subfolder + updateKey">
          <div class="flix-form-group flix-well">
            <fileList :user="user" :subfolder="subfolder" :onChange="setSubfolder" />
          </div>
          <div class="flix-form-group">
            <createFolder :user="user" :subfolder="subfolder" :onReady="setReload" />
          </div>
          <div class="flix-form-group">
            <uploader :user="user" :subfolder="subfolder" :onReady="setReload" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    uploader () {
      return import('./upload')
    },
    fileList () {
      return import('./fileList')
    },
    createFolder () {
      return import('./createFolder')
    },
    shareFolder () {
      return import('./shareFolder')
    }
  },
  props: {
    username: String
  },
  data () {
    return {
      user: false,
      subfolder: '',
      updateKey: 0
    }
  },
  computed: {

  },
  mounted () {
    this.getUser()
  },
  methods: {
    setReload () {
      this.updateKey++
    },
    setSubfolder (subfolder) {
      this.subfolder = subfolder
    },
    getUser () {
      this.$store.state.users.forEach(function (user) {
        if (user.username === this.$route.params.username) {
          this.user = user
        }
      }.bind(this))
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
