export const deDE = {
  route: {
    signIn: 'Einloggen',
    dashboard: 'Übersicht',
    userList: 'User',
    pageStatistic: 'Statistik',
    reminderErrors: 'Fehler',
    deleteUnvalidated: 'Inaktiv',
    supportChat: 'Support',
    signOut: 'Ausloggen',
    userDetails: 'User-Details',
    cloudDetails: 'Datei in Cloud hochladen'
  },
  message: {
    OnlyForBuisness: 'Nur für Geschäftskunden, Preise zzgl. gesetzl. MwSt.',
    contact: 'Kontakt',
    signIn: 'Einloggen',
    signUp: 'Registrieren',
    blog: 'Blog',
    home: 'Start',
    price: 'Preis | Preise',
    password: 'Passwort',
    repeatPassword: 'Passwort wiederholen',
    repeatEmail: 'E-Mailadresse wiederholen',
    repeatTel: 'Telefonnummer wiederholen',
    repeat: '{name} wiederholen',
    email: 'E-Mailadresse',
    username: 'Benutzername',
    contactPerson: 'Name der Kalender-Kontaktperson',
    contactEmail: 'Emailadresse der Kalender-Kontaktperson',
    show: '{name} ansehen',
    imprintWarning: 'Wichtig: Es besteht eine Verpflichtung zur Angabe eines gültigen Impressums sowie der Datenschutzhinweise. Sonst drohen Abmahnungen von z. B. Mitbewerbern.',
    imprintUse: 'Impressum sowie die Angaben zum Datenschutz werden automatisch für Ihre Termin-Buchungs-Website übernommen. Über den Menüpunkt --Termin-Buchungs-Website bearbeiten-- können Sie die Impressum- und Datenschutz-Angaben, für jede Website individuell, ändern.',
    reminderPreset1: 'Für Kunden mit Elefanten-Langzeitgedächtnis',
    reminderPreset2: 'Für vergessliche Kunden und hohle Nüsse',
    reminderPreset3: 'Für Kunden mit Flamingo-Kurzzeitgedächtnis',
    icalCalendar: 'Termin-Kalender auf Google-Kalender, Android, iPhone etc. nutzen',
    acceptTerms: 'Ich stimme den AGBs zu',
    alreadyAccount: 'Bereits registriert?',
    forgotEmail: 'E-Mail vergessen?',
    maxAppointmentLength: 'Was soll der Kunde buchen können?',
    lastMinuteBookings: 'Kurzfristige Terminbuchungen ausschließen',
    forgotPassword: 'Passwort vergessen?',
    qrCode: 'QR-Code zum Terminkalender',
    send: 'Senden',
    successfullSendet: 'erfolgreich gesendet',
    successfullSaved: 'erfolgreich gespeichert',
    dashboard: 'Meine Termin-Kalender verwalten',
    goTo: 'zu {name} wechseln',
    load: 'lade {name}',
    add: '{name} hinzufügen',
    generate: '{name} jetzt erstellen',
    app: 'App',
    youAreNotLoggedIn: 'Momentan nicht eingeloggt',
    nextAppointment: 'Wann soll der nachfolgende Termin buchbar sein?',
    logout: 'ausloggen',
    confirm: '{name} jetzt bestätigen',
    edit: '{name} bearbeiten',
    profile: 'Profil',
    board: 'Befragung | Befragungen',
    boardOverview: 'Befragungs-Übersicht',
    feedback: 'Bewertung | Bewertungen',
    website: 'Website',
    preview: 'Vorschau',
    createNew: '{name} neu erstellen',
    embed: 'einbetten',
    question: 'Frage | Fragen',
    delete: 'löschen',
    deletionRequest: 'Löschung beantragen',
    comments: 'Kommentar | Kommentare',
    allowComments: 'Kommentare erlauben',
    disallowComments: 'Kommentare nicht erlauben',
    desiredAppointment: 'Wunschtermin',
    appointmentOpenMsg: 'Eine Termin-Zusage oder -Absage steht noch aus. Wir bitten um etwas Geduld. Es erfolgt eine Benachrichtigung per E-Mail.',
    editStatus: 'Bearbeitungs-Status',
    editNumber: 'Bearbeitungs-Nummer (bei Rückfragen etc.)',
    noTitle: 'Kein Titel',
    calendarTitle: 'Gewünschter Titel des Terminkalenders',
    calendarUse: 'Wofür wird ein Online-Termin-Kalender benötigt?',
    noDate: 'Kein Datum',
    generatePDF: 'Generiere PDF',
    yes: 'ja',
    no: 'nein',
    duplicate: 'duplizieren',
    allDataWillBeDeleted: 'Alle Daten werden gelöscht',
    successfullDeleted: 'Erfolgreich gelöscht',
    bookingsWillBeDeleted: '{count} Terminbuchungen werden ebenfalls gelöscht',
    search: 'Suche',
    date: 'Datum',
    noEntry: 'kein Eintrag | keine Einträge',
    back: 'zurück',
    default: 'Standard-{name}',
    color: 'Farbe | Farben',
    imprint: 'Impressum',
    privacy: 'Datenschutz',
    terms: 'AGBs',
    company: 'Firma | Firmen',
    first_name: 'Vorname',
    last_name: 'Nachname',
    adress: 'Adresse | Adressen',
    streetNumber: 'Straße + Hausnr.',
    zip: 'Postleitzahl',
    city: 'Stadt | Städte',
    background_color: 'Hintergrund-Farbe',
    text_color: 'Text-Farbe',
    accent_color: 'Akzent-Farbe',
    getValidationEmail: 'Bestätigungs-Link per E-Mail zur Echtheitsprüfung erhalten',
    getValidationSMS: 'Bestätigungs-Link per SMS zur Echtheitsprüfung erhalten',
    idea: 'Idee | Ideen',
    suggestionForImprovement: 'Verbesserungsvorschlag | Verbesserungsvorschläge',
    mobileNumber: 'Handynummer',
    notPublic: 'Nicht veröffentlicht',
    public: 'Öffentlich einsehbar',
    personalData: 'Persönliche Angaben',
    validation: 'Verifizierung',
    validationLinkSendedTo: 'Verifizierungs-Link erfolgreich an {name} gesendet!',
    thankYou: 'Danke',
    save: '{name} jetzt speichern',
    badge: 'Bewertungs-Label | Bewertungs-Labels',
    embedCode: 'Einbettungs-Code / Widget-Code',
    embedHint: 'Einbettungscode auf der eigenen Website bitte zwischen <body> und </body> einfügen',
    passwordProtected: '{name} ist passwortgeschützt | {name} sind passwortgeschützt',
    whyXStars: 'Warum {stars} Sterne?',
    optional: 'optional',
    ofStars: '{current} von {all} Sternen',
    star: 'Stern | Sterne',
    settings: 'Einstellung | Einstellungen',
    function: 'Funktion | Funktionen',
    invitation: 'Einladung | Einladungen',
    successfullValidated: 'Bewertung wurde erfolgreich verifiziert',
    targetPage: 'Zielseite | Zielseiten',
    targetPageInfo: 'Nach erfolgreicher Terminbuchung wird automatisch eine Erfolgsmeldung generiert und eingeblendet. Alternativ kann auch eine Weiterleitung zu einer individuellen Zielseite erfolgen. Zielseiten können hier eingerichtet und angepasst werden und sind ideal für Zusatzinfos oder Fotos zum anstehenden Termin.',
    reasonWhy: 'Begründung',
    pleaseInsertReasonWhy: '{name} - Bitte jetzt eine Stellungnahme hinterlegen oder die Bewertung löschen. Anschließend wird die Bewertung durch das Support-Team geprüft.',
    userChat: 'Kunden-Chat',
    service: 'Buchbare Leistung | Buchbare Leistungen',
    unreadChats: 'ungelesene Chats',
    falseLogin: 'Benutzername oder Passwort ist falsch',
    supportChat: 'Support-Chat',
    next: 'weiter',
    previous: 'zurück',
    today: 'heute',
    yesterday: 'gestern',
    tomorrow: 'morgen',
    thisYear: 'dieses Jahr',
    lastYear: 'letztes Jahr',
    thisMonth: 'dieser Monat',
    lastMonth: 'letzter Monat',
    menu: 'Menü',
    logo: 'Logo',
    banner: 'Banner-Bild',
    backgroundImage: 'Hintergrund-Bild',
    image: 'Bild',
    cookie: 'Cookie-Einstellung',
    allowEssentialCookies: 'Nur essenzielle Cookies',
    allowAllCookies: 'Alle Cookies',
    notifyMeViaEmail: 'Per E-Mail über eingehende Termine benachrichtigen',
    importFromHitorShit: 'Bewertungen von HitorShit importieren',
    writeAReview: 'Rezension schreiben',
    slider: 'Slider-Box',
    table: 'Tabelle',
    floating: 'Fließend',
    answerFromAdmin: 'Antwort vom Inhaber',
    legend: 'Legende',
    calendar: 'Termin-Kalender | Termin-Kalender',
    open: 'Offen',
    cancellation: 'Absage',
    commitment: 'Zusage',
    days: 'Tage',
    day: 'Tag',
    minutes: 'Minuten',
    minute: 'Minute',
    hours: 'Stunden',
    hour: 'Stunde',
    months: 'Monate',
    month: 'Monat',
    year: 'Jahr',
    years: 'Jahre',
    second: 'Sekunde',
    seconds: 'Sekunden',
    lessSeconds: 'Vor wenigen Augenblicken',
    nextAppointments: 'anstehender Termin | anstehende Termine',
    openAppointments: 'unbestätigter Termin | unbestätigte Termine',
    appointmentOverview: 'Termin-Übersicht',
    overview: 'Übersicht',
    monthsNames: ['', 'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    oClock: 'Uhr',
    weekdays: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'],
    user: 'User',
    note: 'Notiz | Notizen',
    icalDownload: 'Kalender-Datei herunterladen (*.ics)',
    csvDownload: 'Tabelle der Einträge herunterladen (*.csv)',
    message: 'Nachricht | Nachrichten',
    clientChat: 'Kunden-Chat | Kunden-Chats',
    systemNews: 'System-Nachricht | System-Nachrichten',
    result: 'Ergebnis | Ergebnisse',
    pleaseFillOut: 'Bitte ausfüllen',
    appointmentAssistent: 'Termin-Kalender einrichten',
    reload: 'Neu laden',
    holidays: 'Freier Tag | Freie Tage',
    vacations: 'Ferien | Ferien',
    active: 'aktiv',
    inactive: 'inaktiv',
    begin: 'Start',
    end: 'Ende',
    title: 'Titel',
    form: 'Formular | Formulare',
    isRequired: 'Pflichtfeld',
    appointments: 'Termin | Termine',
    chat: 'Interessenten-Chat (Demo)',
    pageBuilder: 'Termin-Buchungs-Website | Termin-Buchungs-Websites',
    quickstart: 'Quick-Start',
    tools: 'Tool | Tools',
    supportAndBlog: 'Support & Blog',
    bestPractice: 'Best-Practice',
    page: 'Seite | Seiten',
    reminder: 'Termin-Erinnerung | Termin-Erinnerungen',
    beforeAppointmentStarts: 'vor Terminbeginn',
    presets: 'Voreinstellung | Voreinstellungen',
    publish: '{name} veröffentlichen',
    membership: 'Mitgliedschaft',
    customer: 'Kunde | Kunden',
    pdfExport: 'als PDF herunterladen',
    selectedData: 'ausgewählte Daten {name}',
    resend: '{name} neu senden',
    embedCalendar: 'Termin-Kalender einbetten',
    needHelp: 'Ich benötige Hilfe',
    tipHeader: 'Für die optimale Nutzung von Bookingflix bitte folgende Tipps beachten:',
    done: 'Bereits erledigt',
    close: '{name} schließen',
    additionalSettings: 'Zusatzeinstellungen',
    appointmentRequests: 'Termin-Anfrage | Termin-Anfragen',
    timeRange: 'Zeitraum',
    vat: 'Umsatzsteuer-ID',
    includeTax: 'inklusive {tax} Umsatzsteuer',
    includeDiscount: 'inklusive {discount} Rabatt',
    tel: 'Telefonnummer | Telefonnummern',
    country: 'Land | Länder',
    ressources: 'Ressource | Ressourcen',
    connect: 'mehrere {name} verknüpfen',
    share: '{name} teilen',
    use: '{name} übernehmen für {for}',
    insertEmbedCode: 'Bitte nachfolgenden Quelltext auf eigene Website einbetten',
    sharedWithMe: 'mit mir geteilt',
    pleaseConfirmEmail: 'Bitte E-Mailadresse {name} bestätigen',
    privateCalendar: 'Private Termine',
    businessCalendar: 'Geschäftliche Termine',
    maxApplicants: 'Wie viele Kunden-Termine können maximal zur selben Zeit stattfinden?',
    applicants: 'Teilnehmer',
    anonymize: '{name} anonymisieren',
    copy: '{name} kopiert',
    location: 'Veranstaltungs-Location',
    status: 'Status',
    willBeUsed: '{name} wird übernommen | {name} werden übernommen',
    verifyText: 'Die Verifizierung der eingegebenen Kontaktdaten war erfolgreich. Nachfolgend alle Infos zum Bearbeitungsstand.',
    backupID: 'Backup-Nummer | Backup-Nummern',
    backup: 'Backup | Backups',
    messageTo: 'Nachricht an {name} | Nachrichten an {name}',
    creditsForSms: 'Für Termin-Erinnerungen per SMS wird Guthaben benötigt (1 Guthaben = 1 SMS). Termin-Erinnerungen per Email sind kostenfrei.',
    upload: '{name} hochladen',
    holidayActiveText: 'Für den angegebenen Zeitraum sind keine Terminbuchungen möglich',
    holidayInactiveText: 'Terminbuchungen sind im ausgewählten Zeitraum weiterhin möglich',
    pleaseUseBookingForm: 'Bitte gewünschten Termin-Kalender auswählen',
    pleaseCheckAppointmentDate: 'Bitte Termindatum überprüfen',
    datesDeactivatedForBookings: 'Folgende Termine sind momentan für Terminbuchungen deaktiviert',
    clickonDayForActivateOrDeaktivate: 'Bitte auf den Tag klicken, der aktiviert oder deaktivert sein soll',
    bookingsAllowed: 'Termin-Buchung ist möglich',
    bookingsNotAllowed: 'Termin-Buchung ist nicht möglich',
    saveForCalendar: 'Für Termin-Kalender {name} übernehmen',
    saveForAllCalendars: 'Für alle Termin-Kalender übernehmen',
    calendarFormat: 'Kalender-Format',
    presentationOfBookings: 'Darstellung der Termin-Buchungen',
    imagePlaceholder: 'z. B. Logo, Symbol-Bild, Portrait, Veranstaltungs-Foto',
    dateTitle: 'Feste Öffnungszeiten',
    dateTitlePlaceholder: 'Für Ladengeschäfte und Dienstleister: z. B. Friseure, Berater, Foto-Studios',
    serviceTitle: 'Angebote / Dienstleistungen',
    serviceTitlePlaceholder: 'Für Dienstleistungen mit unterschiedlicher Termindauer: z. B. Servicetelefonate, Frieseurleistungen',
    serviceDescription: 'Für das Erstellen eigener Angebote / Dienstleistungen bitte einloggen oder registrieren. Im Login-Bereich können unter "Einstellungen" die buchbaren Leistungen definiert werden. Anschließend einfach einen neuen Terminkalender erstellen und die Leistungen mit den "Termin-Anfragen" verbinden.',
    eventTitle: 'Konkretes Event',
    eventTitlePlaceholder: 'Für fixe Termine: z. B. Webinare, Meetings, Veranstaltungen',
    privateTitle: 'Privates Event',
    privateTitlePlaceholder: 'Privates Event ist ideal für: Einmalig datiertes Event ohne Wiederholung wie Geburtstag, Familientreffen etc.',
    categories: 'Kategorie | Kategorien',
    check: 'überprüfe {name}',
    openingWeekdays: 'An welchen Tagen hat Ihr Geschäft geöffnet?',
    startDate: 'Frühstmögliches Datum einer Terminbuchung',
    endDate: 'Letztmögliches Datum einer Terminbuchung',
    bookingPeriod: 'Zeitraum, um Termine im Voraus zu Buchen',
    dailyPauses: 'Tägliche Pausenzeiten',
    excludePause: 'Pausenzeiten werden automatisch ausgeschlossen',
    nextFullHour: 'zur nächsten Stunde',
    nextHalfHour: 'zur nächsten halben Stunde',
    nextQuaterHour: 'zur nächsten viertel Stunde',
    appointmentDuration: 'Termin mit festgelegter Termindauer',
    appointmentService: 'Konkrete buchbare Leistung (Leistungsbeschreibung, Dauer, Preis)',
    assistentSaveInfo: 'Um den erstellten Termin-Kalender zu speichern, bitte jetzt registrieren oder einloggen. Im Login-Bereich können anschließend alle Funktionen von Bookingflix genutzt werden.',
    useDifferentPauses: 'Unterschiedliche Pausenzeiten einstellen',
    warningNullCalendar: 'Bislang wurde noch kein Termin-Kalender erstellt. Bitte jetzt einen Termin-Kalender einrichten.',
    autoConfirm: 'Automatische Zusage bei Terminbuchung',
    showAppointments: 'Anzahl verfügbarer Termine anzeigen',
    repitation: 'Wiederholung | Wiederholungen',
    weeks: 'Woche | Wochen',
    dataProcessingContract: 'Datenverarbeitungsvertrag',
    dataProcessingContractInfo: 'Bitte kontaktieren Sie uns über nachfolgenden Support-Chat. Senden Sie uns Ihre Firmen- und Kontaktdaten. Sie erhalten dann innerhalb von 24 Stunden Ihren Datenverarbeitungsvertrag nach DSGVO.',
    verifyTime: 'Zeit für die Verifizierung des Termins',
    verifyTimeInfo: 'Der Termin bleibt geblockt (nicht buchbar), bis der buchende Kunde diesen verifiziert hat oder die hier angegebene Zeit abgelaufen ist. Die Verifizierung erfolgt per Link-Klick in der Verifizierungs-Email.',
    verifyMethod: 'Email-Verifizierung (DSGVO-Konform)',
    noVerify: 'Keine Verifizierung notwendig',
    bookingMethod: 'Buchungsmodus',
    bookingMethodHint: 'Dieser Buchungsmodus entspricht nicht der europäischen Datenschutzgrundverordnung. Wir empfehlen die Nutzung ausschließlich zu Testzwecken.',
    startBtnTxt: 'Termin jetzt buchen',
    bookingBtnLabel: 'Buttontext der Buchungseinladung',
    signInRequired: 'Neues Login erforderlich...',
    deletionMsg: 'Aufgrund der Bookingflix-Datenlöschfristen werden am %date <b>%count Termineinträge</b> aus dem System gelöscht.\nHierbei handelt es sich um <b>alte Termineinträge</b>, die <b>länger als 1 Monat vergangen</b> sind.',
    webinar: 'Videokonferenz | Videokonferenzen',
    appointmentWebinar: 'Sollen die Kundentermine als Videokonferenz stattfinden?',
    webinarSystem: 'Webinarsystem',
    webinarWaitingMsg: 'Warteraum. Das Webinar wurde noch nicht gestartet.',
    webinarSuccess: 'Das Webinar wurde erfolgreich beendet.',
    appointmentNotification: 'Termin-Benachrichtigung | Termin-Benachrichtigungen',
    afterLastAppointment: 'im Anschluss an den vorherigen Termin',
    appointmentChangeUserMessage: 'Achtung! Eine Terminänderung muss immer durch den Terminverwalter freigegeben werden.',
    checkIn: 'Check-In',
    checkOut: 'Check-Out'
  },
  countries: {
    de: 'Deutschland',
    sui: 'Schweiz',
    at: 'Österreich',
    en: 'England',
    ru: 'Russland',
    fr: 'Frankreich',
    it: 'Italien',
    us: 'USA'
  },
  validationError: {
    header: 'Termin wurde nicht gefunden',
    message: 'Der gewünschte Termin ist nicht im System hinterlegt. Das kann zwei Ursachen haben:\n\n1. Der Klick auf die Terminbestätigung wurde nicht innerhalb der angegebenen Frist durchgeführt.\n2. Der Termin wurde vom Terminverwalter inzwischen gelöscht.'
  },
  reminder: {
    email: 'E-Mail',
    tel: 'SMS',
    standard: 'Noch %s! Bestätigter Termin am %s. Alle Informationen, Stornierung / Datumsänderung unter %s'
  },
  constants: {
    months: ['', 'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    weekdays: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag']
  },
  tips: [
    'Bestätigungs-Email aufrufen und Link in E-Mail anklicken',
    'Profil-Informationen vervollständigen',
    'Termin-Kalender (Buchungs-Formular) einrichten',
    'Termin-Kalender (Buchungs-Formular) auf eigener Website einbinden',
    'Termin-Buchungs-Website bearbeiten',
    'Termin-Erinnerungen einrichten',
    'Freie Tage & Ferien festlegen'
  ],
  startSlides:
  [
    {
      title: 'Willkommen auf Bookingflix!',
      icon: 'important-day',
      content: 'Wir freuen uns über das Interesse, Bookingflix.com für die automatische Online-Termin-Vereinbarung zu nutzen.\n\nBei Fragen bitte einfach an uns wenden. Auch für Anregungen und Ideen sind wir immer offen!\n\nSupportchat: https://bookingflix.com/support-chat.html\nEmail: support@bookingflix.com\nDatenschutz: https://bookingflix.com/privacy.html\nAGB: https://bookingflix.com/terms.html\nImpressum: https://bookingflix.com/imprint.html'
    },
    {
      title: 'Mit Bookingflix auf der sicheren Seite',
      icon: 'ok',
      content: 'Server in Deutschland, mehrere Backups am Tag, HTTPS-Verschlüsselung, Terminvereinbarungs-Prozess bereits nach DSGVO-Standard umgesetzt. Entwickelt in Deutschland, betrieben in Deutschland.\n\nBookingflix ist 14 Tage kostenfrei nutzbar. Dann können Sie entscheiden, ob Sie den Premium-Account weiterhin nutzen oder ob Ihnen der kostenfreie Basic-Account ausreicht. Es erfolgt KEINE automatische Verlängerung.'
    },
    {
      title: 'Tipps für den Start',
      icon: 'flag',
      content: 'Terminformular über Quick-Start anlegen:\nhttps://bookingflix.com/quickstart.html\n\nRegistrieren und Einloggen:\nhttps://bookingflix.com/dashboard.html\n\nProfil-Infos ausfüllen:\nhttps://bookingflix.com/dashboard/user/profile.html\n\nTermin-Formular veröffentlichen:\nhttps://bookingflix.com/dashboard/publish/all.html\n\nTerminerinnerungen einrichten:\nhttps://bookingflix.com/dashboard/reminder/all.html\n\nFreie Tage, Urlaube einstellen:\nhttps://bookingflix.com/dashboard/holidays.html'
    },
    {
      title: 'Termin-Kalender verwalten',
      icon: 'calendar',
      content: 'Jeder bereits erstellte Termin-Kalender lässt sich individuell bearbeiten. Also Abfrage-Felder definieren, Texte ändern und vieles mehr. Der Quick-Start führt Sie durch den Prozess ... Schritt für Schritt: https://bookingflix.com/dashboard/calendars.html'
    },
    {
      title: '10 Frei-SMS zum testen',
      icon: 'sms',
      content: 'Unser Geschenk für den Start: 10 Frei-SMS!\n\nWenn ein Termin bestätigt ist, erhalten die Kunden eine E-Mail UND eine SMS. Das erhöht die Terminzuverlässigkeit. Frei-SMS können auch für Terminerinnerungen genutzt werden. Auch das erhöht die Terminzuverlässigkeit deutlich.\n\nTerminerinnerungen einrichten:\nhttps://bookingflix.com/dashboard/reminder/all.html\n\nWeitere SMS-Credits erwerben\nhttps://bookingflix.com/dashboard/credits.html\n\nTipp: Jetzt Terminerinnerungen einrichten und selbst testweise, aus Kundenperspektive, einen Termin buchen.'
    },
    {
      title: 'Viel Erfolg',
      icon: 'thumbs-up',
      content: 'Wir wünschen viel Erfolg mit Bookingflix und viele zuverlässige Kunden.\n\nMit freundlichen Grüßen\n\nMarcus Hanke\n\nTeam Bookingflix.com\nBebelstr. 15\n58453 Witten\nEmail: support@bookingflix.com\nTelefon: 02302-4007736'
    }
  ],
  email: {
    cancelAppointment: {
      title: 'Terminbuchung {id} abgesagt',
      text: 'Mit dieser E-Mail senden wir wichtige Informationen zum Termin-Kalender {name}.\n\nDie Terminbuchung {id} wurde soeben abgesagt.\n\nBegründung\n---------------------------------\n{reasonWhy}\n\nBuchungs-Details\n---------------------------------\n{details}\n\nWeitere Infos:\n---------------------------------\n{link}\n\n'
    },
    privateEvent: { /* 20-05-04 */
      pageText: 'Die Termin-Buchungs-Seite wurde erfolgreich erstellt. Über nachfolgenden Link ist eine Eintragung möglich: {link}.\n\nDie Termin-Buchungs-Seite kann über nachfolgenden Link bearbeitet werden: {editLink}.\n\nDas Passwort für die Bearbeitung wurde soeben an {email} gesendet.\n\nTipps:\n1. Den Link zur Termin-Buchungs-Seite an mögliche Teilnehmer senden\n2. Den Link auf Facebook oder der eigenen Website veröffentlichen.\n3. Sobald ein Eintrag erfolgt ist, wird eine Infomail an {email} gesendet.\n4. Die Teilnehmerliste kann über folgenden Link eingesehen oder bearbeitet werden: {editLink}',
      title: '{name}', /* 20-05-04 */
      text: 'Mit dieser E-Mail senden wir wichtige Informationen zum Termin-Kalender {name}.\n\nLink der Termin-Buchungs-Seite:\n---------------------------------\n{bookingLink}\n---------------------------------\n\nLink zum Editieren* des Termin-Kalenders:\n---------------------------------\n{editLink}\n*Passwort: {password}\n---------------------------------\n\nÜbersichts-PDF mit allen Infos:\n---------------------------------\n{PDFLink}\n---------------------------------\n\n' /* 20-05-04 */
    },
    ressourcesLogin: {
      title: 'Zugangslink Kalenderverwaltung',
      text: 'Mit dieser E-Mail senden wir den Zugangslink zum Ressourcen-Management. Alle freigegebenen Termin-Kalender können über diesen Zugang verwaltet werden.\n\nZugangslink:\n---------------------------------\n{link}\n---------------------------------\n\nAchtung: Bitte den Zugangslink vertraulich aufbewahren.'
    },
    resendPassword: { /* 20-04-26 */
      title: 'Passwort anpassen', /* 20-04-26 */
      text: 'Mit dieser E-Mail senden wir einen Link, um das Account-Passwort zu ändern.\n\nJetzt das Passwort ändern:\n---------------------------------\n{link}\n---------------------------------\n\nDieser Änderungs-Link ist für 24 Stunden gültig.' /* 20-04-26 */
    },
    registerEmail: { /* 20-04-25 */
      title: 'Bookingflix Account-Registrierung jetzt fertigstellen', /* 20-04-25 */
      text: 'Bitte jetzt auf nachfolgenden Bestätigungs-Link klicken:<hr> {link} <hr>Anschließend ist der Account erfolgreich verifiziert und einsatzbereit.\n\nFür 14 Tage kann der Premium-Account von Bookingflix kostenfrei genutzt werden.\n\nDie Optionen nach den 14 Tagen:\n- Wechsel zum kostenlosen FREE-Account\n- Verlängerung des Premium-Accounts (Keine automatische Verlängerung!)\n\nMehr Infos zu Leistungen und Kosten: <a href="https://bookingflix.com/price.html">https://bookingflix.com/price.html</a>' /* 20-04-25 */
    },
    newAssistentEmail: { /* 20-04-24 */
      title: '{name}', /* 20-04-24 */
      text: 'Sehr gut! Das Termin-Buchungs-Formular {name} wurde erfolgreich erstelt. Es kann sofort auf zwei Arten verwendet werden.\n\n1. Über Termin-Buchungs-Website\n---------------------------------\n{link}\n\n2. Per Einbettungscode auf der eigene Website\n---------------------------------\n<!-- Folgenden Einbettungscode auf Website kopieren -->\n{embedcode}\n<!-- Ende Einbettungscode -->\n\nDas Termin-Buchungs-Formular kann über folgenden Link bearbeitet werden:\n---------------------------------\n{editLink}\n\nWichtig: Zu diesem Termin-Buchungs-Formular wurden automatisch 2 Standard-Termin-Erinnerungen erstellt. Eine Termin-Erinnerung per E-Mail 7 Tage vor dem Termin und eine Termin-Erinnerung per E-Mail 24 Stunden vor dem Termin. Die Termin-Erinnerungen können individuell im Adminbereich angepasst werden.\n\nWeitere Fragen? Jetzt das Support-Center öffnen:\n---------------------------------\n{supportcenter}' /* 20-04-24 */
    },
    sharedEmail: { /* 20-04-21 */
      title: '{name} wurde geteilt', /* 20-04-21 */
      text: '{user} hat soeben Zugriff auf {name} erteilt.\n\nHier der entsprechende Freigabelink:\n{link}\n' /* 20-04-21 */
    },
    validationEmail: {
      title: 'Bitte Bewertung verifizieren',
      text: 'Bitte jetzt die Bewertung durch Klick auf den Link freischalten:\n\n{link}'
    }
  },
  demo: {
    questions: 'Wie bewerten Sie die Produktqualität? | Wie bewerten Sie den Service?',
    targetText: 'Danke für die ehrliche {star}-Sterne Bewertung',
    privateTitlePlaceholder: 'z.B. Einladung 40. Geburtstag Max Mustermann',
    eventTitlePlaceholder: 'z.B. Tag der offenen Tür bei Firma Mustermann',
    dateTitlePlaceholder: 'z.B. Tag der offenen Tür bei Firma Mustermann',
    lastMinute: {
      0: 'Last-Minute Buchungen sind erlaubt',
      15: 'bis 15 Minuten vor Termin',
      30: 'bis 30 Minuten vor Termin',
      45: 'bis 45 Minuten vor Termin',
      60: 'bis 1 Stunde vor Termin',
      120: 'bis 2 Stunden vor Termin',
      180: 'bis 3 Stunden vor Termin',
      240: 'bis 4 Stunden vor Termin',
      300: 'bis 5 Stunden vor Termin',
      360: 'bis 6 Stunden vor Termin',
      720: 'bis 12 Stunden vor Termin',
      1440: 'bis 1 Tag vor Termin',
      2880: 'bis 2 Tage vor Termin'
    },
    service: {
      category: 'Friseurstudio',
      description: 'Termin bei unserer Friseurin',
      data: [
        { name: 'Susi', title: 'Waschen, schneiden, fönen', price: 50, time: 120 },
        { name: 'Ella', title: 'Haare-Färben', price: 100, time: 180 },
        { name: 'Anna', title: 'Spitzen-Schneiden', price: 20, time: 60 }
      ]
    }
  },
  company: {
    name: 'Bookingflix',
    benefits: ['Befragungen erstellen', 'Bewertungen erhalten', 'Auf Website einbetten'],
    mainBenefit: 'Reputation steigern'
  },
  terms: {
    privacyAccept: 'Ich stimme der Erhebung und Verarbeitung meiner personenbezogenen Daten ausdrücklich zu.',
    termsAccept: 'Ich stimme den Allgemeinen Geschäftsbedingungen zu.'
  }
}
