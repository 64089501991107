<template>
  <div v-if="calendar" >
    <b-flix-h3>{{ calendar.length }} {{ $tc('message.calendar', calendar.length) }}</b-flix-h3>
    <b-flix-slider variant="textSlider">
        <slot name="menu" slot="menu">
          <ul>
            <li v-for="i in calendar.length" :key="i">
              {{ i }}
            </li>
          </ul>
        </slot>
        <slot name="content" slot="content">
          <ul>
            <li v-for="cal in calendar" :key="cal.ID">
              <b-flix-layout variant="main-page" :settings="JSON.parse(JSON.stringify({arrow: true, style: 'gray'}))" style="text-align: center;">

                  <b-flix-h4><flixIcon id="calendar" /> {{ cal.title }}</b-flix-h4>
                  <div class="flix-form-group">{{ cal.date.split(' ').join(', ') }} {{ $t('message.oClock') }}</div>
                  <div class="flix-form-group">
                    <b-flix-button variant="contained-green" :href="$flix_url + '/' + user.language + '/' + user.username + '/' + cal.ID + '/index.html'" target="_blank"><flixIcon id="globe" /> {{ $tc('message.calendar', 1) }}</b-flix-button>
                  </div>
                  <div class="flix-form-group">
                    <br>
                    <downloadCSV :user="user" :calendar="cal" />
                  </div>
                  <!-- <bookings :user="user" :calendar="cal" /> -->

                  <div class="flix-form-group">
                    <b-flix-button variant="text" :callback="function () { setLogin(cal.ID) }"><flixIcon id="keys" /> {{ $t('message.signIn') }}
                    </b-flix-button>
                  </div>
                  <div class="flix-form-group">

                    <b-flix-button variant="text" :callback="function () { loadSummary = true; setSummary(cal.ID) }"><flixIcon id="settings" /> {{ $tc('message.settings', 2) }}</b-flix-button>
                    <b-flix-button variant="text" target="_blank" :href="'https://api.bookingflix.com/assets/external/adminer/index.php?server=bookingflix-database.c41nvvpzmt15.eu-central-1.rds.amazonaws.com%3A3306&username=root&select=flix_bookins&&select=flix_bookings&columns%5B0%5D%5Bfun%5D=&columns%5B0%5D%5Bcol%5D=&where%5B0%5D%5Bcol%5D=form&where%5B0%5D%5Bop%5D=%3D&where%5B0%5D%5Bval%5D='+ cal.ID +'&order%5B0%5D=&limit=50&text_length=100&db=flix_user_' + user.ID ">Adminer</b-flix-button>
                  </div>
                  <transition name="fadeIn">
                    <assistentSummary :key="summary" v-if="summary === cal.ID" :user="user" :assistent="cal"/>
                  </transition>
                <div>
                  {{ user.md5_user }}
                  <br>
                  {{ cal.ID }}
                </div>

              </b-flix-layout>
              <b-flix-layout :settings="JSON.parse(JSON.stringify({arrow: true, style: 'gray-light'}))" variant="main-page" style="text-align: center;">
                <div style="margin-top: 20px; text-align: left; padding: 10px;">
                  <flix-calendar-bold language="de" :user="user.md5_user" :calendar="cal.ID" />
                </div>
              </b-flix-layout>
            </li>
          </ul>
        </slot>
      </b-flix-slider>
  </div>
</template>
<script>
export default {
  components: {
    // bookings () { return import('@/components/dashboard/userDetails/bookings') },
    assistentSummary () { return import('@/components/dashboard/assistentSummary/') },
    downloadCSV () { return import('@/components/dashboard/userDetails/downloadCSV/') }
  },
  props: {
    user: Object
  },
  data () {
    return {
      calendar: false,
      summary: false
    }
  },
  methods: {
    setReorderCalendar (ret) {
      var dates = {}
      Object.keys(ret).forEach(function (i) {
        dates[ret[i].date] = i
      })

      var datesSort2 = JSON.parse(JSON.stringify(Object.keys(dates))).sort()

      var newDates = []
      datesSort2.forEach(function (v) {
        newDates.push(ret[dates[v] * 1])
      })
      this.calendar = newDates
    },
    setSummary (cal) {
      if (this.summary === cal) {
        this.summary = false
        return false
      }
      this.summary = cal
    },
    setLogin (ID) {
      var data = {
        redirect: this.$flix_url + '/calendar-assistent/' + ID + '.html',
        user: this.user.md5_user,
        date: new Date().getTime()
      }
      var url = this.$flix_url + '/admin/redirect/' + btoa(JSON.stringify(data)) + '.html'
      window.open(url)
    },
    getCalendar () {
      this.$flix_post({
        url: 'assistent/get_assistents',
        data: {
          user: this.user.md5_user
        },
        callback: function (ret) { if (ret.data[0]) { this.setReorderCalendar(ret.data[1]) } }.bind(this)
      })
    }
  },
  mounted () {
    this.getCalendar()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
