<template>
  <div v-if="backups">
    <b-flix-h2 variant="green" v-if="!backups[0]">{{ backups[1] }}</b-flix-h2>
    <div v-if="backups[0]">
      <b-flix-p>
        <b-flix-h2 variant="green"
          >{{ backups[1].length }} Backups gefunden</b-flix-h2
        >
      </b-flix-p>
      <b-flix-p>
        <b-flix-button
          variant="contained-blue"
          :callback="() => (open = !open)"
          >{{ !open ? 'Anzeigen' : 'Ausblenden' }}</b-flix-button
        >
      </b-flix-p>
      <b-flix-list v-if="open">
        <li v-for="(backup, index) in backups[1]" :key="backup">
          <b-flix-h3>Backup #{{ index + 1 }}</b-flix-h3>
          <bookingDate
            style="
              text-transform: uppercase;
              display: flex;
              align-items: center;
              gap: 5px;
            "
            :date="
              backup.split('--')[0] +
              ' ' +
              backup.split('--')[1].split('-').join(':')
            "
          />
          <div>
            <b-flix-button
              variant="text-icon"
              v-if="!backupHandler.ID"
              :callback="() => (backupHandler.ID = backup)"
              >Aktivieren</b-flix-button
            >
            <div v-if="backupHandler.ID === backup">
              <div
                v-if="!backupHandler.status"
                style="display: flex; align-items: center; gap: 5px"
              >
                Auf diese Version zurück spielen?
                <b-flix-button
                  variant="contained-blue"
                  :callback="
                    () => {
                      backupHandler.ID = false
                      backupHandler.status = false
                    }
                  "
                  ><b-flix-icon icon="menu-close"></b-flix-icon
                ></b-flix-button>
                <b-flix-button
                  variant="contained-blue"
                  :callback="() => setConfirm()"
                  ><b-flix-icon icon="check"></b-flix-icon
                ></b-flix-button>
              </div>
              <div v-if="backupHandler.status === 'confirm'">
                <b-flix-loader />
                <b-flix-alert variant="info"
                  >Bitte 2 Minuten Geduld</b-flix-alert
                >
              </div>
              <div v-if="backupHandler.status === 'success'">
                <b-flix-alert variant="success">Erfolgreich</b-flix-alert>
              </div>
            </div>
          </div>
        </li>
      </b-flix-list>
    </div>
  </div>
</template>
<script>
export default {
  name: 'backups',
  components: {
    bookingDate() {
      return import('@/components/dashboard/pageStatistic/date')
    }
  },
  props: {
    user: {
      type: [Object]
    }
  },
  data() {
    return {
      backupHandler: { ID: false, status: false },
      backups: false,
      open: false
    }
  },
  computed: {},
  mounted() {
    this.getBackups()
  },
  methods: {
    async setConfirm() {
      this.backupHandler.status = 'confirm'

      const form = new FormData()
      form.append('user', this.user.ID)
      form.append('backup', this.backupHandler.ID)

      await fetch('https://backupserver.bookingflix.com/api/restoring', {
        method: 'POST',
        body: form
      })

      await new Promise((resolve) => setTimeout(resolve, 1000 * 60 * 2))

      this.backupHandler.status = 'success'

      await new Promise((resolve) => setTimeout(resolve, 1000))
      this.backupHandler.ID = false
      this.backupHandler.status = false
    },
    async getBackups() {
      const formData = new FormData()
      formData.append('user', this.user.ID)

      const data = await fetch(
        'https://backupserver.bookingflix.com/api/list',
        {
          method: 'POST',
          body: formData
        }
      ).then((ret) => ret.json())

      this.backups = data
    }
  }
}
</script>
<style lang="sass" scoped></style>
