<template>
  <div class="flix-container">
    <userList :users="users" v-if="users && users.length" />
    <b-flix-alert variant="danger" v-else>{{ $tc('message.noEntry', 2) }}</b-flix-alert>
  </div>
</template>
<script>
import validated from './getUnvalidatedUsers.js'
export default {
  components: {
    userList () { return import('./list') }
  },
  props: {},
  data () {
    return {
      users: false
    }
  },
  computed: {

  },
  mounted () {
    this.getUsers()
  },
  methods: {
    getUsers () {
      var u = new validated()
      u.data = this.$store.state.users
      this.users = u.getUnvalidated()
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
