import router from '@/router'
import store from '@/store'

export default {

  install (Vue, options) {
    Vue.prototype.$getUserVariables = function () {
      if (router.app.$route.name !== null && router.app.$route.name.indexOf('share') !== -1) {
        if (typeof localStorage.getItem('flix_login_shares') !== 'undefined' && localStorage.getItem('flix_login_shares')) {
          var data = {
            info: JSON.parse(atob(localStorage.getItem('flix_login_shares')))
          }
          return {
            user: {
              md5_id: data.info.user,
              username: data.info.username,
              language: data.info.language,
              ID: data.info.userID
            },
            state: 'share'
          }
        } else {
          return false
        }
      } else {
        return { user: store.getters.user, state: 'default' }
      }
    }
    Vue.prototype.$checkEmail = function (email) {
      var reg = /\S+@\S+\.\S+/
      return reg.test(email)
    }
    Vue.prototype.$createUsername = function (username) {
      return username.toLowerCase().trim()
        .split('ä').join('ae')
        .split('ö').join('oe')
        .split('ü').join('ue')
        .split('ß').join('ss')
        .replace(/[^\w\s]/gi, '')
    }
  }
}
