export default {
  install (Vue, options) {
    Vue.prototype.$getEmbedCode = function (data) {
      var code = {
        data: {
          load: {
            user: data.user,
            ID: data.ID,
            demo: false,
            lang: data.language
          }
        },
        saveSettings: 'https://cal.bookingflix.com/vendor/save/' + data.language + '.js'
      }

      var embedCode = '[script](function(a,b,c,d,e,f,g){ f=b.createElement(c);g=b.currentScript;f.async=1;f.src=d; g.parentNode.replaceChild(f,g,b);f.onload=function(){new get_cal(29).build(f,e);};})(window,document,"script","https://cal.bookingflix.com/vendor/embed.js", "' + btoa(JSON.stringify(code)) + '");[/script]'

      return embedCode.split('[').join('<')
        .split(']').join('>')
    }
  }
}
