<template>
  <div v-if="loaded">
    <chartist
      ratio="ct-major-second"
      style="max-height: 125px"
      type="Line"
      :data="chartData"
      :options="chartOptions"
    >
    </chartist>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      loaded: false,
      chartData: {
        labels: [],
        series: [[]]
      },
      chartOptions: {
        lineSmooth: true,
        low: 0,
        showArea: true,
        stackBars: true,
        fullWidth: false
      }
    }
  },
  methods: {
    getChart() {
      var dates = {}
      const months = ['', 'Jan', 'Feb', 'Mrz', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']
      this.$store.state.users.forEach(
        function (user) {
          if (user.status === 'validated') {
            var date = this.$createDate(user.registration)
            if (
              typeof dates[
                date.getFullYear() +
                  '-' +
                  this.$getNullsBefore(date.getMonth() + 1) +
                  '<br>(' +
                  months[date.getMonth() + 1] +
                  ')'
              ] === 'undefined'
            ) {
              dates[
                date.getFullYear() +
                  '-' +
                  this.$getNullsBefore(date.getMonth() + 1) +
                  '<br>(' +
                  months[date.getMonth() + 1] +
                  ')'
              ] = 0
            }
            dates[
              date.getFullYear() +
                '-' +
                this.$getNullsBefore(date.getMonth() + 1) +
                '<br>(' +
                months[date.getMonth() + 1] +
                ')'
            ] += 1
          }
        }.bind(this)
      )

      var keys = Object.keys(dates)
      keys.sort()

      this.chartData.labels = []
      this.chartData.series[0] = []

      for (var i = keys.length - 9; i <= keys.length - 1; i++) {
        this.chartData.labels.push(keys[i])
        this.chartData.series[0].push(dates[keys[i]])
      }
      this.loaded = true
    }
  },
  mounted() {
    this.getChart()
  },
  computed: {}
}
</script>
<style lang="sass" scoped></style>
