import Vue from 'vue'
import FlixCalBold from 'bookingflix_calendar_bold'
import statistic from 'bookingflix_statistic'
import chartist from 'v-chartist'

window.Vue = Vue
Vue.use(FlixCalBold)

Vue.use(statistic)
Vue.component('chartist', chartist, { name: 'VueChartist' })
