<template>
  <div style="text-align: center; display: flex; align-items: center; justify-content: center; padding: 20px">
    <b-flix-loader v-if="!loaded"/>
    <vue-json-pretty v-if="data" :data="data" style="width: 100%"></vue-json-pretty>
  </div>
</template>
<script>
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
export default {
  components: {
    VueJsonPretty
  },
  props: {
    assistent: Object,
    user: Object
  },
  data () {
    return {
      data: false,
      loaded: false
    }
  },
  methods: {
    getData () {
      this.loaded = false
      this.$flix_post({
        data: {
          user: this.user.md5_user,
          ID: this.assistent.ID
        },
        url: 'assistent/get_data',
        callback: function (ret) {
          this.data = ret.data[1]
          delete this.data.form
          this.loaded = true
        }.bind(this)
      })
    }
  },
  mounted () {
    this.getData()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
