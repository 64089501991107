<template>
  <div class="flix-text-right">
      <b-flix-button variant="text" :callback="getData">Download CSV</b-flix-button>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      date: new Date()
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    forceFileDownload (response) {
      const url = window.URL.createObjectURL(new Blob([response]))
      const link = document.createElement('a')
      link.href = url

      link.setAttribute('download', this.$getNullsBefore(this.date.getFullYear()) + '-' + this.$getNullsBefore((this.date.getMonth() + 1)) + '-' + this.$getNullsBefore(this.date.getDate()) + '-' + this.$getNullsBefore(this.date.getHours()) + '-' + this.$getNullsBefore(this.date.getMinutes()) + '-' + this.$getNullsBefore(this.date.getSeconds()) + '-' + this.$flix_brand.toLowerCase() + '.csv')
      document.body.appendChild(link)
      link.click()
    },
    generateCSV (data) {
      var splitter = ';'
      var row = ''
      var keys = []
      data.forEach(function (k) {
        keys = Object.keys(k)
        row += '"' + Object.values(k).join('"' + splitter + '"') + '"'
        row += '\n'
      })
      var response = ''
      response += '"' + keys.join('"' + splitter + '"') + '"'
      response += '\n'
      response += row

      this.forceFileDownload(response)
    },
    getCellEntry (values) {
      var row = []
      values.forEach(function (v) {
        var i = v
        if (typeof v === 'object') {
          i = v.join(' | ')
        }
        row.push('"' + i + '"')
      })
      return row
    },
    getData () {
      this.$flix_post({
        url: 'user/get_billing_data',
        data: {
          user: this.$store.state.user.md5_id
        },
        callback: function (ret) { this.generateCSV(ret.data[1]) }.bind(this)
      })
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
