<template>
  <div>
    <div v-if="!loaded" style="display: flex; align-items: center; justify-content: center;">
      <b-flix-loader size="lg" />
    </div>
    <div v-else-if="users">
      <div class="flix-row">
        <div class="flix-col-md-12 flix-text-center">
          <b-flix-h2 variant="green" style="display: block; margin-bottom: 20px">Letzte Terminbuchungen ({{ users.length }})</b-flix-h2>
        </div>
        <div class="flix-col-md-12" style="margin-bottom: 20px; display: flex; align-items: center; justify-content: center;">
          <b-flix-list variant="number">
            <li v-for="user in users" :key="user.ID">
              <strong>{{ user.username }}</strong>
              <bookingDate class="flix-text-danger" :date="user.date" />
              <br>
              <router-link  :to="{name: 'userDetails', params: {username: user.username}}" :class="{'flix-active': user.status === 'validated'}">
                Zum Account
              </router-link>
            </li>
          </b-flix-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    bookingDate () { return import('@/components/dashboard/pageStatistic/date') }
  },
  props: {},
  mounted () {
    this.getData()
  },
  computed: {

  },
  data () {
    return {
      users: false,
      loaded: false
    }
  },
  methods: {
    getData () {
      this.$flix_post({
        url: 'statistic/get_last_bookings',
        data: {
          user: this.$store.state.user.md5_id
        },
        callback: function (ret) {
          this.loaded = true
          if (ret.data[0]) {
            this.users = ret.data[1]
          }
        }.bind(this)
      })
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
