<template>
  <div>
    <div class="flix-btn" :class="checkData(0)" @click.prevent="onChange">
      <slot :name="'0'">on</slot>
    </div>
    <div class="flix-btn" :class="checkData(1)" @click.prevent="onChange">
      <slot :name="'1'">off</slot>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: [String, Number],
    onChange: Function
  },
  data () {
    return {

    }
  },
  methods: {
    checkData (id) {
      if (this.data === this.$slots[id][0].text) {
        return { 'flix-btn-primary': true }
      }
      return { 'flix-btn-default': true }
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .flix-btn
    transition: all 0.5s ease-in
  .flix-btn-primary
    animation: changBtn 0.5s ease-in
    transform: scale(1)
  .flix-btn-default
    opacity: 0.5

  @keyframes changBtn
    0%
      transform: scale(1)
    33%
      transform: scale(1.2)
    66%
      transform: scale(0.8)
    100%
      transform: scale(1)

</style>
