<template>
  <div>
    <flixIcon :id="'check'" class="icon" />
    <b-flix-h1>{{ validation }}%</b-flix-h1>
    <b-flix-p>{{ $t('message.validation') }}</b-flix-p>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      validation: 0
    }
  },
  methods: {
    getValidationCount () {
      if (!this.$store.state.users || !this.$store.state.users.length) {
        this.getValidationCount()
        return false
      }
      var validation = 0
      this.$store.state.users.forEach(function (user) {
        if (user.status === 'validated') {
          validation += 1
        }
      })
      this.validation = Math.round(validation / this.$store.state.users.length * 10000) / 100
    }
  },
  mounted () {
    this.getValidationCount()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .count
    font-size: 40pt
    font-weight: bold
  .icon
    font-size: 40pt
    display: block
    margin-bottom: 5px
</style>
