<template>
  <div class="flix-container">
    <searchForm
      :users="$store.state.users"
      :callback="
        (s) => {
          setSearch(s)
        }
      "
    />
    <billingData />
    <b-flix-h2
      >Datensatz: {{ (page - 1) * breakpoint + 1 }} - {{ total }} von
      {{ $store.state.users.length }}</b-flix-h2
    >
    <getMenu
      :key="pages + '1'"
      :page="page"
      :pages="pages"
      :callback="setStart"
    />
    <div class="flix-row">
      <div v-for="user in users" :key="user.ID" class="flix-col-md-4">
        <div class="flix-list-group">
          <router-link
            :to="{ name: 'userDetails', params: { username: user.username } }"
            class="flix-list-group-item flix-html-a flix-text-center"
            :class="{ 'flix-active': user.status === 'validated' }"
          >
            <flixIcon
              v-if="user.status === 'validated'"
              id="user"
              class="icon"
            />
            <flixIcon v-else id="remove" class="icon" />

            <div class="flix-html-small flix-html-strong">
              {{ user.username }}
            </div>
            <hr class="flix-html-hr" />
            <div class="flix-html-small">
              {{ user.email }}<br />
              <span style="font-size: 8pt"
                >Registrierung: {{ user.registration }}</span
              >
            </div>
            <div class="flix-html-small flix-text-left">#{{ user.ID }}</div>
          </router-link>
        </div>
      </div>
    </div>
    <getMenu
      :key="pages + '2'"
      :page="page"
      :pages="pages"
      :callback="setStart"
    />
  </div>
</template>
<script>
export default {
  components: {
    billingData() {
      return import('@/components/dashboard/billingData/')
    },
    searchForm() {
      return import('@/components/dashboard/userList/search')
    },
    getMenu() {
      return import('@/components/dashboard/userList/menu')
    }
  },
  props: {},
  data() {
    return {
      page: 1,
      breakpoint: 12,
      pages: 0,
      users: false,
      total: 0
    }
  },
  methods: {
    getTotal(l) {
      var total = this.page * this.breakpoint
      if (l.length < total) {
        return l.length
      }
      return total
    },
    setStart(i) {
      this.page = i
      this.users = this.getUsers(this.$store.state.users)
      this.total = this.getTotal(this.$store.state.users)
    },
    setSearch(s) {
      this.users = this.getUsers(s)
      this.total = this.getTotal(s)
      this.page = 1
      this.pages = Math.ceil(s.length / this.breakpoint)
    },
    getUsers(u) {
      var user = []
      for (
        var i = this.page * this.breakpoint - this.breakpoint;
        i < this.page * this.breakpoint;
        i++
      ) {
        if (typeof u[i] !== 'undefined') {
          user.push(u[i])
        }
      }
      return user
    }
  },
  mounted() {
    if (!this.$store.state.search) {
      this.users = this.getUsers(this.$store.state.users)
      this.total = this.getTotal(this.$store.state.users)
      this.pages = Math.ceil(this.$store.state.users.length / this.breakpoint)
    }
    if (this.$store.state.search) {
      var r = []
      this.$store.state.users.forEach((user) => {
        if (
          user.username
            .toLowerCase()
            .indexOf(this.$store.state.search.toLowerCase()) !== -1
        ) {
          r.push(user)
        } else if (user.ID === this.$store.state.search) {
          r.push(user)
        }
      })
      this.setSearch(r)
    }
  },
  computed: {}
}
</script>
<style lang="sass" scoped>
.icon
  font-size: 25pt
  margin-bottom: 5px
</style>
