<template>
  <div class="flix-row" style="margin-bottom: 20px;">
    <div class="flix-col-md-12">
      <b-flix-card>
        <div slot="header"/>
        <div slot="title"/>
        <div slot="content">
          <form @submit.prevent="findSearch">
            <div class="flix-form-group">
              <b-flix-input :label="placeholder" :value="search" :callback="setSearch" />
            </div>
            <div class="flix-form-group">
              <b-flix-button variant="contained-green" :callback="findSearch">{{ $t('message.search') }}</b-flix-button>
              <button type="submit" style="display: none"></button>
            </div>
          </form>
        </div>
      </b-flix-card>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    callback: Function,
    users: Array
  },
  mounted () {
    if (this.$store.state.search) {
      this.setSearch()
    }
  },
  computed: {

  },
  data () {
    return {
      placeholder: this.$t('message.username') + ' / ID',
      search: this.$store.state.search
    }
  },
  methods: {
    setSearch (s) {
      this.search = s
    },
    findSearch () {
      if (typeof this.search === 'undefined') {
        this.search = this.$store.state.search
      }
      var search = this.search
      this.$store.commit('setSearch', search)
      var r = []
      this.users.forEach((user) => {
        if (user.username.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
          r.push(user)
        } else if (user.ID === search) {
          r.push(user)
        }
      })

      this.callback(r)
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
