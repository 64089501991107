<template>
  <div v-if="shareUrl">
    <h3 class="flix-html-h3">Geteilter Ordner</h3>
    <input type="text" :value="shareUrl" class="flix-form-control" spellcheck="false">
    <a class="flix-html-a" :href="shareUrl" target="_blank"><flixIcon id="share" /> {{ $t('message.goTo', {name: 'geteiltem Ordner'}) }}</a>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    user: Object
  },
  data () {
    return {
      folder: 'uploads/' + this.user.ID + '/',
      shareUrl: ''
    }
  },
  computed: {

  },
  mounted () {
    this.createFolder(function () { this.getShareUrl() }.bind(this))
  },
  methods: {
    createFolder (callback) {
      this.uploading = true
      this.$flix_post({
        url: 'cloud/folder',
        data: {
          user: this.$store.state.user.md5_id,
          folder: this.folder,
          action: 'create'
        },
        callback: function (ret) {
          callback()
        }
      })
    },
    getShareUrl () {
      this.$flix_post({
        url: 'cloud/share',
        data: {
          user: this.$store.state.user.md5_id,
          folder: this.folder
        },
        callback: function (ret) {
          this.shareUrl = ret.data[1].url
        }.bind(this)
      })
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
