import Vue from 'vue'
import VueI18n from 'vue-i18n'

import { deDE } from '@/language/de_DE.js'
import { enEN } from '@/language/en_EN.js'

Vue.use(VueI18n)

const messages = {
  de: deDE,
  en: enEN
}

// Create VueI18n instance with options
export default new VueI18n({
  locale: 'de', // set locale
  fallbackLocale: 'de',
  messages // set locale messages
})
