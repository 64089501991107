<template>
  <span>
    <span v-if="!open">
      <a href="#" @click.prevent="open = true" class="flix-btn flix-btn-danger flix-btn-xs"><flixIcon id="bin" /></a>
    </span>
    <span class="check" v-else>
      <flixIcon id="bin" />
      <a href="#" @click.prevent="open = false" class="flix-html-a flix-text-default">{{ $t('message.no') }}</a>
      <a href="#" @click.prevent="callback()" class="flix-html-a flix-text-danger">{{ $t('message.yes') }}</a>
    </span>
  </span>
</template>
<script>
export default {
  components: {},
  props: {
    callback: Function
  },
  mounted () {

  },
  computed: {

  },
  data () {
    return {
      open: false
    }
  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
  .check
    flixIcon, a
      display: inline-block
      margin-right: 6px
</style>
