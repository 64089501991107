<template>
  <div>
    <div class="flix-text-right flix-form-group">
      <a href="#" @click.prevent="open = !open" class="flix-html-a flix-html-small flix-text-danger"><flixIcon id="bin" /> {{ $t('message.user') }} {{ $t('message.delete') }}</a>
    </div>
    <transition name="fadeIn">
      <div class="flix-text-right" v-if="open" :key="open">
        <form @submit.prevent="setCheck">
          <div class="flix-form-group">
            <label class="flix-html-label">{{ $t('message.pleaseFillOut') }}: <span class="flix-text-strong">{{ user.username }}</span></label>
            <input v-model="check" type="text" class="flix-form-control flix-text-right" :placeholder="$t('message.pleaseFillOut')"/>
          </div>
          <div class="flix-form-group">
            <button class="flix-btn flix-btn-danger">{{ $t('message.delete') }}</button>
          </div>
          <div class="flix-text-danger">
            <flixIcon id="warning-sign" /> {{ $t('message.allDataWillBeDeleted') }}
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    user: Object
  },
  data () {
    return {
      open: false,
      check: ''
    }
  },
  methods: {
    setCheck () {
      if (this.user.username !== this.check) {
        return false
      }
      this.$store.commit('setDeleteUser', this.user.username)
      this.$flix_post({
        url: 'user/delete_user',
        data: {
          user: this.user.md5_user
        },
        callback: function (ret) {
          this.$router.push({ name: 'userList' })
        }.bind(this)
      })
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
