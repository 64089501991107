<template>
    <img :key="key" src="/assets/img/logo/Logo.svg" :title="$t('company.name')" :class="{animation: ani}" @mouseenter="setHover"/>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {
      key: new Date().getTime(),
      ani: false
    }
  },
  methods: {
    setHover () {
      // this.key = new Date().getTime()
      this.ani = true
      setTimeout(function () {
        this.ani = false
      }.bind(this), 500)
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
img
  width: 100%
.animation
  animation: 0.5s logoani ease-in
@keyframes logoani
  from
    opacity: 1
    transform: scale(1)
  50%
    opacity: 1
    transform: scale(1.1)
  to
    opacity: 1
    transform: scale(1)

</style>
