export const enEN = {
  message: {
    OnlyForBuisness: 'Only for business customers, prices plus VAT.',
    contact: 'contact',
    signIn: 'login',
    signUp: 'register',
    blog: 'blog',
    home: 'start',
    price: 'price | prices',
    password: 'password',
    repeatPassword: 'repeat password',
    repeatEmail: 'repeat e-mail address',
    repeatTel: 'repeat phone number',
    repeat: 'repeat {name}',
    email: 'e-mail address',
    username: 'username',
    show: 'view {name}',
    acceptTerms: 'I agree to the terms and conditions',
    alreadyAccount: 'Already registered?',
    forgotEmail: 'E-mail forgotten?',
    forgotPassword: 'Password forgotten?',
    send: 'send',
    successfullSendet: 'successfully sent',
    successfullSaved: 'stored successfully',
    dashboard: 'Dashboard overview',
    goTo: 'change to {name} ',
    add: 'add {name}',
    app: 'app',
    youAreNotLoggedIn: 'Currently not logged in',
    logout: 'log out',
    confirm: 'confirm now {name}',
    edit: 'edit {name}',
    profile: 'profile',
    board: 'survey | surveys',
    boardOverview: 'survey overview',
    feedback: 'evaluation | reviews',
    website: 'website',
    preview: 'preview',
    createNew: 'recreate {name}',
    embed: 'embed',
    question: 'question | questions',
    delete: 'delete',
    deletionRequest: 'request deletion',
    comment: 'comment',
    yes: 'yes',
    no: 'no',
    duplicate: 'duplicate',
    allDataWillBeDeleted: 'All data will be deleted',
    search: 'search',
    date: 'date',
    noEntry: 'no record | no records',
    back: 'back',
    default: 'standard-{name}',
    color: 'colour | colours',
    imprint: 'imprint',
    privacy: 'data protection',
    terms: 'General Terms and Conditions',
    company: 'company | companies',
    first_name: 'first name',
    last_name: 'surname',
    adress: 'address | addresses',
    zip: 'postcode',
    city: 'city | cities',
    background_color: 'background colour',
    text_color: 'text colour',
    accent_color: 'accent colour',
    getValidationEmail: 'Receive confirmation link by e-mail for authentication',
    getValidationSMS: 'Receive confirmation link via SMS for authentication',
    idea: 'idea | ideas',
    suggestionForImprovement: 'Improvement suggestion | Improvement suggestions',
    mobileNumber: 'Mobile phone number',
    notPublic: 'Not published',
    public: 'Publicly available',
    personalData: 'Personal details',
    validation: 'Validation',
    validationLinkSendedTo: 'Validation link successfully sent to {name}!',
    thankYou: 'Thanks',
    save: 'save now {name}',
    badge: 'Rating label | Rating labels',
    embedCode: 'Embedding code',
    embedHint: 'Please insert embed code on your own website between <body> and </body> ',
    passwordProtected: '{name} is password protected | {name} are password protected',
    whyXStars: 'Why {stars} stars?',
    optional: 'optional',
    ofStars: '{current} of {all} stars',
    star: 'star | stars',
    settings: 'Setting | Settings',
    function: 'Function | Functions',
    invitation: 'Invitation | Invitations',
    successfullValidated: 'Evaluation has been successfully validated',
    targetPage: 'Target page | Target pages',
    reasonWhy: 'Justification',
    pleaseInsertReasonWhy: '{name} - Please leave a comment now or delete the rating. The rating will then be reviewed by the support team.',
    userChat: 'User Chat',
    service: 'Bookable service | Bookable services',
    unreadChats: 'unread chats',
    falseLogin: 'Username or password is incorrect',
    next: 'next',
    previous: 'previous',
    today: 'today',
    yesterday: 'yesterday',
    thisYear: 'this year',
    lastYear: 'last year',
    thisMonth: 'this month',
    lastMonth: 'last month',
    menu: 'Menu',
    logo: 'Logo',
    banner: 'Banner image',
    backgroundImage: 'Background image',
    cookie: 'Cookie setting',
    allowEssentialCookies: 'Only essential cookies',
    allowAllCookies: 'All cookies',
    notifyMeViaEmail: 'Notify by e-mail about incoming reviews',
    importFromHitorShit: 'Import ratings from HitorShit',
    writeAReview: 'Write a review',
    slider: 'Slider Box',
    table: 'Table',
    floating: 'Flowing',
    answerFromAdmin: 'Reply from the holder',
    legend: 'Legend',
    calendar: 'Appointment Calendar | Appointment Calendars',
    open: 'Open',
    cancellation: 'Cancellation',
    commitment: 'commitment',
    days: 'Days',
    day: 'Day',
    minutes: 'minutes',
    minute: 'minute',
    hours: 'hours',
    hour: 'hour',
    months: 'months',
    month: 'month',
    year: 'year',
    years: 'years',
    second: 'second',
    seconds: 'seconds',
    lessSeconds: 'A few moments ago',
    nextAppointments: 'next appointment | next appointments',
    openAppointments: 'unconfirmed appointment | unconfirmed appointments',
    appointmentOverview: 'appointment overview',
    overview: 'Overview',
    monthsNames: ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    oClock: 'o’clock',
    weekdays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    user: 'User',
    note: 'Note  | Notes',
    icalDownload: 'Download calendar file (*.ics)',
    csvDownload: 'Download table of entries (*.csv)',
    message: 'Message| Messages',
    clientChat: 'Client Chat | Client Chats',
    systemNews: 'System message | System messages',
    result: 'result | results',
    pleaseFillOut: 'Please fill in',
    appointmentAssistent: 'Set up appointment calendar',
    reload: 'Reload',
    holidays: 'free day | free days',
    vacations: 'Holidays  | Holidays',
    active: 'active',
    inactive: 'inactive',
    begin: 'Start',
    end: 'End',
    title: 'Title',
    form: 'Form | Forms',
    isRequired: 'required field',
    appointments: 'appointment | appointments',
    chat: 'Interested parties chat (demo)',
    pageBuilder: 'Appointment Booking Page | Appointment booking pages',
    quickstart: 'Quick Start',
    tools: 'Tool | Tools',
    supportAndBlog: 'Support & Blog',
    bestPractice: 'Best-Practice',
    page: 'page | pages',
    reminder: 'Appointment reminder | Appointment reminders',
    beforeAppointmentStarts: 'before appointment start',
    presets: 'Preset | Presets',
    publish: 'publish {name}',
    membership: 'Membership',
    customer: 'customer | customers',
    pdfExport: 'download as PDF file',
    selectedData: 'selected data {name}',
    resend: 'resend {name}',
    embedCalendar: 'Embed appointment calendar',
    needHelp: 'I need help',
    tipHeader: 'For the optimal use of Terminflix please note the following tips:',
    done: 'Already done',
    close: 'close {name}',
    additionalSettings: 'Additional settings',
    appointmentRequests: 'Appointment request | Appointment requests',
    timeRange: 'Period',
    vat: 'Value added tax ID',
    tel: 'Phone number | Phone numbers',
    country: 'Country | Countries',
    ressources: 'Resource | Ressources',
    share: 'share {name}',
    privateCalendar: 'private appointments',
    businessCalendar: 'business appointments',
    startBtnTxt: 'book appointment now',
    bookingBtnLabel: 'buttontext for booking'
  },
  reminder: {
    email: 'E-mail',
    tel: 'SMS',
    standard: 'Still  %s! Confirmed appointment on %s. All information, cancellation / date change under  %s'
  },
  constants: {
    months: ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    weekdays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  },
  tips: [
    'Open confirmation email and click on the link in the email',
    'Complete profile information',
    'Set up appointment calendar (booking form)',
    'Integrate appointment calendar (booking form) on your own website',
    'Edit appointment booking website',
    'Set up appointment reminders',
    'Set free days & holidays'
  ],
  email: {

    registerEmail: { /* 20-04-25 */
      title: 'Bookingflix Account-Registrierung jetzt fertigstellen', /* 20-04-25 */
      text: 'Bitte jetzt auf nachfolgenden Bestätigungs-Link klicken:<hr> {link} <hr>Anschließend ist der Account erfolgreich verifiziert und einsatzbereit.\n\nFür 14 Tage kann der Premium-Account von Bookingflix kostenfrei genutzt werden.\n\nDie Optionen nach den 14 Tagen:\n- Wechsel zum kostenlosen FREE-Account\n- Verlängerung des Premium-Accounts (Keine automatische Verlängerung!)\n\nMehr Infos zu Leistungen und Kosten: <a href="https://bookingflix.com/price.html">https://bookingflix.com/price.html</a>' /* 20-04-25 */
    },
    conflictEmail: {
      title: 'There is a conflict with an evaluation',
      text: 'Hello {name},\nI donot agree with the rating on {url}.\n\nBASICATIONS\n-------------------------------------------------------------------------------------------------------------------------------------------------n\n\n I ask for the independent deletion of this rating or for a short statement. The link will be automatically attached to this message. Thank you for your feedback within 7 days.\n\n{user}\n\n-----------------------------------------------------------------------------nLink to the comment:\n{link}\n---------------------------------'
    },
    invitationEmail: {

      text: 'Hello.\nThis e-mail contains an invitation to the survey: \'{board}.\'\n\nYou can leave a rating via the following link: \n{link}\n\n\n Thank you very much for short-term feedback!\n\n{name}'
    },
    validationEmail: {
      title: 'Please validate evaluation',
      text: 'Please unlock the rating now by clicking on the link:\n\n{link}'
    }
  },
  demo: {
    questions: 'How do you evaluate the product quality? | How do you rate the service?',
    targetText: 'Thanks for the honest {star}-star rating'
  },
  company: {
    name: 'Terminflix',
    benefits: ['Create surveys', 'get ratings', 'Embed on website'],
    mainBenefit: 'Enhance reputation'
  },
  terms: {
    privacyAccept: 'I expressly agree to the collection and processing of my personal data.',
    termsAccept: 'I agree to the general terms and conditions.'
  },
  home: {
    infoblock: {
      title: 'Authenticated customer reviews for serious, trustworthy reputation management',
      description: [
        ['Evaluation Management', 'Receive and publish honest customer feedback. Simple. Direct. Honest.'],
        ['Include or own evaluation website', 'Publish reviews on your own website. Or create a rating website with individual content with just a few clicks. '],
        ['Free of charge for all', 'Hitklick.com can be used free of charge by private individuals and companies. ']

      ]
    }
  }
}
