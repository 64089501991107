<template>
  <div v-if="membership" :key="update">
    <div>
      <b-flix-button variant="contained-blue" :callback="function () { open = !open }">
        <membershipHead :user="user" :endDate ="date" :membership="membership" />
      </b-flix-button>
      <transition name="fadeIn">
        <div style="display: block; margin-top: 20px;" :key="open" v-if="open">
          <membershipEdit :user="user" :endDate ="date" :membership="membership" :callback="getMembership" />
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    membershipHead () { return import('./head') },
    membershipEdit () { return import('./edit') }
  },
  props: {
    user: Object
  },
  data () {
    return {
      membership: false,
      date: false,
      update: 0,
      open: false
    }
  },
  computed: {

  },
  mounted () {
    this.getMembership()
  },
  methods: {
    getNewDate () {
      var date = this.membership.details.end_date.split(' ')
      date[0] = date[0].split('-')
      date[1] = date[1].split(':')

      this.date = {
        year: (date[0][0] * 1),
        month: (date[0][1] * 1),
        day: (date[0][2] * 1),
        hour: (date[1][0] * 1),
        minutes: (date[1][1] * 1),
        seconds: (date[1][2] * 1)
      }
    },
    getMembership () {
      this.$flix_post({
        url: 'user/get_membership',
        data: {
          user: this.user.md5_user
        },
        callback: function (ret) { this.update = new Date().getTime(); this.membership = ret.data[1]; this.getNewDate() }.bind(this)
      })
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
