<template>
  <div>
    <div style="">
      <b-flix-slider variant="textSlider">
        <slot name="menu" slot="menu">
          <ul>
            <li> Letzte Buchungen </li>
            <li> Alle Termine </li>
            <li> Größte Accounts </li>
            <li> Letzte Backups </li>
          </ul>
        </slot>
        <slot name="content" slot="content" >
          <ul>
            <li>
              <b-flix-card>
                <div slot="header">
                </div>
                <div slot="title">
                </div>
                <div slot="content">
                  <lastBookings />
                </div>
              </b-flix-card>
            </li>
            <li>
              <b-flix-card>
                <div slot="header">
                </div>
                <div slot="title">
                </div>
                <div slot="content" style="text-align: center; line-height: 4rem">
                  <totalBookings />
                </div>
              </b-flix-card>
            </li>
            <li>
              <b-flix-card>
                <div slot="header">
                </div>
                <div slot="title">
                </div>
                <div slot="content">
                  <bigAccounts />
                </div>
              </b-flix-card>
            </li>
            <li>
              <b-flix-card>
                <div slot="header">
                </div>
                <div slot="title">
                </div>
                <div slot="content">
                  <userBackups></userBackups>
                </div>
              </b-flix-card>
            </li>
          </ul>
        </slot>
      </b-flix-slider>
    </div>
    <div />

  </div>
</template>
<script>
export default {
  components: {
    lastBookings () { return import('@/components/dashboard/pageStatistic/last_bookings') },
    bigAccounts () { return import('@/components/dashboard/pageStatistic/big_accounts') },
    userBackups () { return import('@/components/dashboard/pageStatistic/backups') },
    totalBookings () { return import('@/components/dashboard/stats/totalBookings') }
  },
  props: {},
  mounted () {

  },
  computed: {

  },
  data () {
    return {

    }
  },
  methods: {

  }
}
</script>
<style lang="sass" scoped>
  .slider-content>ul li[active]
    position: relative !important
    padding-bottom: 10px
</style>
