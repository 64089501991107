import { render, staticRenderFns } from "./saveBtn.vue?vue&type=template&id=ab308764&scoped=true"
import script from "./saveBtn.vue?vue&type=script&lang=js"
export * from "./saveBtn.vue?vue&type=script&lang=js"
import style0 from "./saveBtn.vue?vue&type=style&index=0&id=ab308764&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab308764",
  null
  
)

export default component.exports