const validated = function () {
  this.data = []
  this.referenceDate = new Date()

  this.getReferenceDate = function () {
    this.referenceDate = new Date()
    this.referenceDate.setDate(this.referenceDate.getDate() - 2)
  }

  this.getUnvalidated = function () {
    this.getReferenceDate()

    var data = []
    this.data.forEach(function (d) {
      if (d.status !== 'validated' && this.checkDate(d.registration)) {
        data.push(d)
      }
    }.bind(this))
    return data
  }

  this.checkDate = function (date) {
    var d = date.split(' ')
    d[0] = d[0].split('-')
    d[1] = d[1].split(':')

    var newDate = new Date()
    newDate.setYear(d[0][0])
    newDate.setMonth(d[0][1] * 1 - 1)
    newDate.setDate(d[0][2])
    newDate.setHours(d[1][0])
    newDate.setMinutes(d[1][1])
    newDate.setSeconds(d[1][2])

    if (newDate.getTime() > this.referenceDate.getTime()) {
      return false
    }
    return true
  }
}

export default validated
