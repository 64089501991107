<template>
  <div>
    <b-flix-h2 style="display: block; margin-bottom: 20px">
      Maximale Kalender
    </b-flix-h2>
    <b-flix-radio
      style="margin-bottom: 20px"
      variant="inline"
      :values="getValues()"
      :value="max"
      :callback="setMax"
    >
    </b-flix-radio>

    <b-flix-button variant="contained-green" :callback="setChangeMaxUser"
      >jetzt ändern</b-flix-button
    >
    <div v-if="success">
      <b-flix-alert variant="success">{{
        $t('message.successfullSaved')
      }}</b-flix-alert>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    user: Object
  },
  data() {
    return {
      max: JSON.parse(JSON.stringify(this.user)).max_calendar * 1,
      interval: false,
      success: false
    }
  },
  computed: {},
  mounted() {},
  beforeDestroy() {
    if (this.interval) {
      clearTimeout(this.interval)
    }
  },
  methods: {
    getValues() {
      const values = {}

      for (let i = 1; i <= 100; i++) {
        values[i] = i
      }
      return values
    },
    setMax(m) {
      this.max = Object.values(m)[0]
    },
    setChangeMaxUser() {
      this.user.max_calendar = this.max
      this.$flix_post({
        url: 'user/change_max_calendar',
        data: {
          admin: this.$store.state.user.md5_id,
          max_calendar: this.max,
          user: this.user.md5_user
        },
        callback: function (ret) {
          if (ret.data[0]) {
            this.$store.commit('setChangeUser', this.user)
            this.success = true
            this.interval = setTimeout(
              function () {
                this.success = false
              }.bind(this),
              2000
            )
          }
        }.bind(this)
      })
    }
  }
}
</script>
<style lang="sass" scoped></style>
