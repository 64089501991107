<template>
  <div class="flix-container-fluid">
    {{ $t('message.logout') }}
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {
    this.$store.commit('setLogin', false)
    this.$router.push({ name: 'signIn' })
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
