const base = 'dashboard'
const dashboard = [
  {
    path: '/' + base + '/overview.html',
    name: 'dashboard',
    component: () => import('@/components/dashboard/')
  },
  {
    path: '/' + base + '/sign-out.html',
    name: 'signOut',
    component: () => import('@/components/dashboard/signOut/')
  },
  {
    path: '/' + base + '/page-statistic.html',
    name: 'pageStatistic',
    component: () => import('@/components/dashboard/pageStatistic/')
  },
  {
    path: '/' + base + '/delete-univalidated.html',
    name: 'deleteUnvalidated',
    component: () => import('@/components/dashboard/deleteUnvalidated/')
  },
  {
    path: '/' + base + '/reminder-errors.html',
    name: 'reminderErrors',
    component: () => import('@/components/dashboard/reminderErrors/')
  },
  {
    path: '/' + base + '/support-chat.html',
    name: 'supportChat',
    component: () => import('@/components/dashboard/supportChat/')
  },
  {
    path: '/' + base + '/user-list.html',
    name: 'userList',
    component: () => import('@/components/dashboard/userList/')
  },
  {
    path: '/' + base + '/user-details/:username.html',
    name: 'userDetails',
    component: () => import('@/components/dashboard/userDetails/')
  },
  {
    path: '/' + base + '/cloud/:username.html',
    name: 'cloudDetails',
    component: () => import('@/components/dashboard/cloud/')
  }

]

export { dashboard }
