import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: false,
    users: false,
    assistents: {},
    bookings: {},
    search: ''
  },
  mutations: {
    setChangeUser (store, newUser) {
      store.users.forEach(function (user, key) {
        if (user.ID === newUser.ID) {
          store.users[key] = newUser
          return false
        }
      })
    },
    setSearch (store, search) {
      store.search = search
    },
    setBookings (store, data) {
      store.bookings = Object.assign(store.bookings, data)
    },
    setAssistent (store, data) {
      store.assistents = Object.assign(store.assistents, data)
    },
    getLogin (store, data) {
      if (typeof localStorage.getItem('flix_admin') === 'string') {
        store.user = JSON.parse(atob(localStorage.getItem('flix_admin')))
      }
    },
    setLogin (store, data) {
      store.user = data

      if (data) {
        localStorage.setItem('flix_admin', btoa(JSON.stringify(data)))
      } else {
        localStorage.removeItem('flix_admin')
      }
    },
    setUsers (store, data) {
      store.users = data
    },
    setDeleteUser (store, username) {
      store.users.forEach(function (user, key) {
        if (user.username === username) {
          store.users.splice(key, 1)
          return false
        }
      })
    }
  },
  actions: {
    getUsers (store, data) {
      if (store.users) {
        return store.users
      }
      Vue.prototype.$flix_post({
        url: 'user/get_all_user',
        data: {
          user: store.state.user.md5_id
        },
        callback: function (ret) { store.commit('setUsers', ret.data[1]) }
      })
    }
  },
  modules: {
  },
  getters: {
  }
})
