import Vue from 'vue'

export default {
  install () {
    Vue.prototype.$setRedirect = function (url) {
      if (url === 'https://web.bookingflix.com/price') {
        url = 'https://web.bookingflix.com/prices'
      }
      window.location = url
    }
  }
}
